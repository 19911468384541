<template>
    <v-header></v-header>
    <v-bannerDenuncias></v-bannerDenuncias>
    <v-form-consultas></v-form-consultas>
    <v-footer></v-footer>
</template>

<script>
import vHeader from '@/components/vHeader.vue';
import vFooter from '@/components/vFooter.vue';
import vBannerDenuncias from '@/components/denuncias/vBannerDenuncias.vue';
import vFormConsultas from '@/components/consultas/vFormConsultas.vue';

export default {
    name: 'vConsulta',

    components: {
        vHeader,
        vFooter,
        vBannerDenuncias,
        vFormConsultas,
    }
}
</script>