<template>
    <section class="formulario-denuncia">
        <div class="container">
            <div class="section-title">
                <h3 class="color-text">Denuncias</h3>
            </div>
            <Form @submit="enviar"> <!-- crear -->
                <div class="pasos-form-denuncias">
                    <div class="container" @mouseout="botonDenuncia()" @mouseover="botonDenuncia()">
                        <!-- denunciante -->
                        <div class="row">
                            <div v-if="showForm">
                                <div class="row justify-content-between mb-3">
                                    <div class="p-0 col-lg-10 col-6">
                                        <h5 class="text-lg-start text-center color-text">
                                            Denunciante/s
                                        </h5>
                                    </div>
                                </div>
                                <div class="row data justify-content-between">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row row-cards">
                                                <div class="col-md-6">
                                                    <label class="form-label"><b>Nombre y apellido (*)</b></label>
                                                    <Field type="text" class="form-control form-control-sm" aria-label="nombreApellido" v-model="denunciantes.nombreApellido" name="nombreApellido" required="true" />
                                                    <ErrorMessage name="nombreApellido" class="error-feedback" style="color: red; font-size: 14px" />
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-label"><b>Tipo de documento (*)</b></label>
                                                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="denunciantes.tipoDocumento">
                                                        <option selected value="">seleccione una opción</option>
                                                        <option value="Cédula de Identidad">Cédula de identidad civil</option>
                                                        <option value="Pasaporte">Número de pasaporte</option>
                                                        <option value="Otros">Otros</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-label"><b>Nro. de documento (*)</b></label>
                                                    <Field type="text" class="form-control form-control-sm" aria-label="documento" v-model="denunciantes.nroDocumento" name="documento" />
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="form-label"><b>Domicilio (*)</b></label>
                                                    <Field type="text" class="form-control form-control-sm" aria-label="domicilio" v-model="denunciantes.domicilio" name="domicilio" />
                                                </div>
                                                <div class="col-md-2">
                                                    <label class="form-label"><b>Correo electrónico (*)</b></label>
                                                    <Field type="email" class="form-control form-control-sm" aria-label="email" v-model="denunciantes.email" name="email" />
                                                </div>
                                                <div class="col-md-2">
                                                    <label class="form-label"><b>Teléfono (*)</b></label>
                                                    <Field type="text" class="form-control form-control-sm" aria-label="tel" v-model="denunciantes.telefono" name="tel" />
                                                </div>
                                                <div class="col-md-2">
                                                    <label class="form-label"><b>Sexo (*)</b></label>
                                                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="denunciantes.sexo">
                                                        <option selected value="">seleccione una opción</option>
                                                        <option value="Masculino">Masculino</option>
                                                        <option value="Femenino">Femenino</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-3">
                                                        <div class="form-label"><b>Proteger datos</b></div>
                                                        <!-- <div>
                                                            <label class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" v-model="denunciantes.protegerDatos">
                                                            </label>
                                                        </div> -->

                                                        <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                            <!-- <button type="button" class="btn btn-outline-primary" :class="denuncia.protegerDatos ? 'btn-outline-primary' : 'btn-primary'">Si</button>
                                                            <button type="button" class="btn btn-primary" :class="denuncia.protegerDatos ? 'btn-primary' : 'btn-outline-primary'">No</button> -->
                                                            <button type="button" class="btn" @click="activeProtegerDatos(true)" :class="denunciantes.protegerDatos ? 'btn-primary' : 'btn-outline-primary'">Si&nbsp;</button>
                                                            <button type="button" class="btn" @click="activeProtegerDatos(false)" :class="denunciantes.protegerDatos ? 'btn-outline-primary' : 'btn-primary'">No</button>
                                                        </div>

                                                        <!-- <div class="btn-group d-flex mt-4" role="group" aria-label="Basic example">
                                                            <button type="button" class="btn btn-outline-primary w-20 p-3" :class="denuncia.denunciaOtraInstitucion ? 'active-btn-denuncia-yes' : 'btn-yes'" @click="activeChange_other(true)">
                                                                SI
                                                            </button>
                                                            <button type="button" class="btn btn-primary btn-sm w-20 p-3" :class="denuncia.denunciaOtraInstitucion ? 'btn-no' : 'active-btn-denuncia-no'" @click="activeChange_other(false)">
                                                                NO
                                                            </button>
                                                        </div> -->

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="text-end">
                                                <button class="btn btn-sm btn-primary" type="button" @click="nuevoDenunciante()">
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-wrapper table-responsive p-0" v-if="mostrarDenunciante">
                                        <table class="table table-info-d mb-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="col-lg-1">N°</th>
                                                    <th scope="col" class="col-lg-2">Nombre y apellido</th>
                                                    <th scope="col" class="col-lg-1">Tipo de documento</th>
                                                    <th scope="col" class="col-lg-1">Nro. de documento</th>
                                                    <th scope="col" class="col-lg-2">Domicilio</th>
                                                    <th scope="col" class="col-lg-1">Correo electrónico</th>
                                                    <th scope="col" class="col-lg-1">Teléfono</th>
                                                    <th scope="col" class="col-lg-1">Sexo</th>
                                                    <th scope="col" class="col-lg-1">Proteger datos</th>
                                                    <th scope="col" class="col-lg-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="!carga_denunciantes">
                                                <tr v-for="(denunciantes, index) in arrayDenunciante" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ denunciantes.nombreApellido }}</td>
                                                    <td>{{ denunciantes.tipoDocumento }}</td>
                                                    <td>{{ denunciantes.nroDocumento }}</td>
                                                    <td>{{ denunciantes.domicilio }}</td>
                                                    <td>{{ denunciantes.email }}</td>
                                                    <td>{{ denunciantes.telefono }}</td>
                                                    <td>{{ denunciantes.sexo }}</td>
                                                    <td v-if="denunciantes.protegerDatos == true">SI</td>
                                                    <td v-if="denunciantes.protegerDatos == false">NO</td>
                                                    <td>
                                                        <img @click="eliminarDenunciante(index)" class="px-3" src="assets/icons/denuncias/Icon-feather-trash.svg" alt="trash" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <!-- informacion denunciado -->
                        <div class="row">
                            <div>
                                <div class="row justify-content-between mb-3">
                                    <div class="p-0 col-lg-10 col-6">
                                        <h5 class="ms-1 mb-4 color-text">
                                            Denunciado/s
                                        </h5>
                                    </div>
                                </div>
                                <div class="row data justify-content-between">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row row-cards">
                                                <div class="col-md-6">
                                                    <label class="form-label"><b>Nombre y apellido</b></label>
                                                    <input type="text" class="form-control form-control-sm" aria-label="nombreApellido" v-model="denunciados.nombreApellido" />
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-label"><b>Tipo de documento</b></label>
                                                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="denunciados.tipoDocumento">
                                                        <option selected value="">seleccione una opción</option>
                                                        <option value="Cédula de Identidad">Cédula de identidad civil</option>
                                                        <option value="Pasaporte">Número de pasaporte</option>
                                                        <option value="Otros">Otros</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-label"><b>Nro. de documento</b></label>
                                                    <input type="text" class="form-control form-control-sm" aria-label="documento" v-model="denunciados.nroDocumento" />
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-label"><b>Institución (*)</b></label>
                                                    <Select2 class="form-control form-control-sm" v-model="denunciados.institucion" :options="instituciones" :settings="{ text: instituciones }" @select="seleccionOtraOEE($event)" />
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-label"><b>Cargo o función</b></label>
                                                    <input type="text" class="form-control form-control-sm" aria-label="cargo" v-model="denunciados.cargo" />
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-label"><b>Sexo</b></label>
                                                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="denunciados.sexo">
                                                        <option selected value="">seleccione una opción</option>
                                                        <option value="Masculino">Masculino</option>
                                                        <option value="Femenino">Femenino</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="text-end">
                                                <button class="btn btn-sm btn-primary" type="button" @click="nuevoDenunciado(), limpiarDenunciados()">
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-wrapper table-responsive p-0" v-if="mostrarDenunciado">
                                        <table class="table table-info-d mb-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="col-lg-1">Nro.</th>
                                                    <th scope="col" class="col-lg-2">Nombre y apellido</th>
                                                    <th scope="col" class="col-lg-1">Tipo de documento</th>
                                                    <th scope="col" class="col-lg-1">Nro. de documento</th>
                                                    <th scope="col" class="col-lg-3">Institución</th>
                                                    <th scope="col" class="col-lg-2">Cargo o función</th>
                                                    <th scope="col" class="col-lg-1">Sexo</th>
                                                    <th scope="col" class="col-lg-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(denunciado, index) in arrayDenunciado" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ denunciado.nombreApellido }}</td>
                                                    <td>{{ denunciado.tipoDocumento }}</td>
                                                    <td>{{ denunciado.nroDocumento }}</td>
                                                    <td>{{ denunciado.institucion }}</td>
                                                    <td>{{ denunciado.cargo }}</td>
                                                    <td>{{ denunciado.sexo }}</td>
                                                    <td>
                                                        <img @click="eliminarDenunciado(index)" class="px-3" src="assets/icons/denuncias/Icon-feather-trash.svg" alt="trash" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <!-- datos varios de la denuncia -->
                        <div class="row">
                            <div class="p-0 col-lg-10 col-6">
                                <h5 class="ms-1 mb-4 color-text">
                                    Lugar del hecho
                                </h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="row data justify-content-between">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row row-cards">
                                            <div class="col-md-6">
                                                <label class="form-label"><b>Dirección</b></label>
                                                <input type="text" class="form-control form-control-sm" aria-label="Dirección" v-model="denuncia.direccion" />
                                            </div>
                                            <div class="col-md-2">
                                                <label class="form-label"><b>Departamento</b></label>
                                                <Select2 class="form-control form-control-sm" v-model="denuncia.departamento" :options="deptos" :settings="{ value: deptos }" @change="actualizarMun" />
                                            </div>
                                            <div class="col-md-2">
                                                <label class="form-label"><b>Ciudad</b></label>
                                                <Select2 class="form-control form-control-sm" v-model="denuncia.ciudad" :options="municipiosFiltrados" :settings="{ text: municipiosFiltrados }" @change="actualizarBar" />
                                            </div>
                                            <div class="col-md-2">
                                                <label class="form-label"><b>Barrio</b></label>
                                                <Select2 class="form-control form-control-sm" v-model="denuncia.barrio" :options="barriosFiltrados" :settings="{ text: barriosFiltrados }" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-4 col-12 px-1 mb-md-4 mb-3">
                                <div>
                                    <h5 class="ms-1 color-text">Fecha en que ocurrieron los hechos (*)</h5>
                                </div>
                                <input type="date" class="form-control form-control-sm p-2" v-model="denuncia.fechaDeLosHechos" />
                            </div>
                        </div>
                        <!-- descripcion de los hechos -->
                        <div class="row">
                            <div class="mb-3 p-0 input-text-tarea">
                                <div>
                                    <h5 class="ms-1 mt-5 color-text">Descripción de los Hechos y/o Circunstancias (*)</h5>
                                </div>
                                <textarea
                                    maxlength="2000"
                                    class="form-control form-control-sm"
                                    id=""
                                    v-model="denuncia.descripcion"
                                    placeholder="Logre proveer todos los datos que consideren oportunos, y hagan lugar a la presente denuncia; con la mayor precisión posible, a fin de que haga efectivo el análisis del mismo."
                                    rows="6"
                                ></textarea>
                                <div class="text-end">
                                    <p>Max. caracteres: {{ remainingChars }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- Tipo de denuncia -->
                        <div class="row">
                            <div class="col-lg-4 col-12 px-1 mb-md-4 mb-3">
                                <div>
                                    <h5 class="ms-1 color-text">La denuncia corresponde a:</h5>
                                </div>
                                <div>
                                    <label class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="denuncia.esHechoPuniblePenal" checked>
                                        <span class="form-check-label">Hechos punibles con relevancia penal</span>
                                    </label>
                                    <label class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="denuncia.esHechoPunibleAdministrativo">
                                        <span class="form-check-label">Hechos punibles con relevancia administativa </span>
                                    </label>
                                </div>
                                <!-- <input type="date" class="form-control form-control-sm p-2" v-model="denuncia.fechaDeLosHechos" /> -->
                            </div>
                        </div>
                        <!-- denuncia ante otra institucion -->
                        <div class="row">
                            <div class="buttons my-5 row justify-content-lg-start justify-content-center">
                                <div class="col-lg-6 col-4 p-0 m-1">
                                    <div>
                                        <h5 class="color-text">
                                            ¿Fue presentada una denuncia ante otra institución?
                                        </h5>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn" :class="denuncia.denunciaOtraInstitucion ? 'btn-primary' : 'btn-outline-primary'" @click="activeChange_other(true)">
                                                SI
                                            </button>
                                            <button type="button" class="btn" :class="denuncia.denunciaOtraInstitucion ? 'btn-outline-primary' : 'btn-primary'" @click="activeChange_other(false)">
                                                NO
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="col-lg-6 col-4 p-0 m-1" v-if="denuncia.denunciaOtraInstitucion">
                                    <h5 class="text-lg-start text-center color-text">
                                        Nombre de institución
                                        <div class="tool-one"></div>
                                    </h5>
                                    <div class="col-lg-12">
                                        <Select2 class="form-control form-control-sm" v-model="denuncia.otraInstitucion" :options="instituciones" :settings="{ text: instituciones }" @select="seleccionOtraOEE($event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- seleccionar  archivo adjunto-->
                        <div class="row">
                            <div v-show="false">
                                <label for="file-upload">Seleccionar archivo:</label>
                                <input ref="fileSelector" type="file" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .mp3, .mp4" id="file-upload" @change="onFileChange" />
                            </div>

                            <div class="tabla mt-5">
                                <div class="p-0 row justify-content-between mx-1">
                                    <div class="col-md-9 col-7 p-0">
                                        <h5 class="ms-0 mb-md-4 mb-3 info-denuncia__title color-text">
                                            Información de la denuncia<span class="ms-3">(Sólo se aceptan archivos JPG, PNG, PDF, DOC, DOCX, XLS, XLSX, MP3 y MP4.)</span>
                                        </h5>
                                    </div>
                                    <div class="col-xl-2 col-md-3 col-5 p-0 mb-3 text-end">
                                        <button type="button" class="btn btn-outline-primary text-center btn-archivo" @click="btnFileSelector()">
                                            Subir archivo
                                            <img class="ps-md-3 ps-1 pb-1 px-0" src="assets/img/icons/denuncias/icon-subir-archivo.svg" alt="" />
                                        </button>
                                    </div>
                                </div>

                                <div class="table-wrapper px-1">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nombre del archivo</th>
                                                <th scope="col">Tipo de archivo</th>
                                                <!-- <th scope="col">Tamaño</th> -->
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="archivos in fileInfos" :key="archivos">
                                                <td>{{ archivos.descripcion }}</td>
                                                <td>{{ archivos.contentType }}</td>
                                                <!-- <td>{{ archivos.url }}</td> -->
                                                <td class="d-flex justify-content-end">
                                                    <img @click="eliminarAdjunto(index)" class="px-3" src="assets/icons/denuncias/Icon-feather-trash.svg" alt="trash" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <!-- boton guardar -->
                        <div class="btn-send text-center mt-5">
                            <button type="submit" class="btn btn-primary" v-if="vacio">                                
                                Enviar denuncia
                            </button>
                        </div>

                    </div>
                </div>
            </Form>
            <div v-if="loading" class="loader">
                <h5>Enviando Formulario..<br />Por favor espere..</h5>
            </div>
            <div v-if="loadingdocument" class="loader">
                <h5>Alzando documentos..<br />Por favor espere..</h5>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import JSONService from "@/services/JSON.service";
import DenunciaService from "@/services/denuncia.service";
import Select2 from "vue3-select2-component";
import html2pdf from "html2pdf.js";
import xml2js from 'xml2js';
// import api from '@/services/api.js';
import { Form, Field, ErrorMessage } from "vee-validate";
// import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg"

export default {
    name: "vFormDenuncias",
    components: { Select2, Form, Field, ErrorMessage },
    data() {
        return {
            inputText: "",
            maxLength: 2000,
            showForm: true,
            active: true,
            active_rendiciones: true,
            active_presentacion: false,
            active_licitaciones: false,
            active_manejo: false,
            active_funcionarios: false,
            active_otros: false,
            funcionario: "",
            carga_comprobante: false,
            copiar_comprobante: "",
            copiado: false,
            vacio: false,
            municipio: null,
            archivos: [],
            isOtraInstitucion: false,
            selectArchivo: null,
            maximoPermitido: 50000000,

            docBase64: '',

            instituciones: [],
            deptos: [],
            mun: [],
            bar: [],
            arrayDenunciante: [],
            arrayDenunciado: [],
            arrayFile: [],
            carga_denunciados: true,
            carga_denunciantes: false,
            mostrarDenunciante: false,
            mostrarDenunciado: false,

            denuncia: {
                uid: "",
                comprobante: "",
                tipoDenuncia: "rendiciones",
                denunciaAnonima: true,
                denunciaOtraInstitucion: false,
                otraInstitucion: "",
                fechaDeLosHechos: "",
                institucionDenunciada: "",
                otraInstitucionDenunciada: null,
                direccion: "",
                barrio: "",
                ciudad: "",
                departamento: "",
                descripcion: "",
                urlDocumentoAdjunto: "",
                pretension: "",
                estado: null,
                administradoPorUsuario: null,
                fechaCreacion: new Date(),
                denunciados: [],
                denunciantes: [],
                archivos: [],
                base64data: "",
                esHechoPuniblePenal: true,
                esHechoPunibleAdministrativo: false,
                denunciaAnteOtraInst: ''
            },
            denunciados: {
                nombreApellido: "",
                tipoDocumento: "",
                nroDocumento: "",
                institucion: "",
                cargo: "",
                sexo: "",
                esEliminado: false,
                denunciaUid: null,
            },
            denunciantes: {
                nombreApellido: "",
                tipoDocumento: "",
                nroDocumento: "",
                domicilio: "",
                email: "",
                telefono: "",
                sexo: "",
                protegerDatos: false,
                esEliminado: false,
                denunciaUid: null,
            },
            //conf. pdf
            pdfOptions: {
                margin: { top: 10, right: 10, bottom: 10, left: 10 },
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "mm", format: "a4", orientation: "p" },
            },
            blobArchive: "",
            iFrame: "",
            exportFilename: "my-custom-file.pdf",
            base64data: "",
            mostrarExp: true,
            loading: false,
            cantArchivo: 0,
            asunto: '',
            expediente: '',
            nombreAsunto: '',
            denunciante: '',
            progressInfos: [],
            selectedFiles: undefined,
            elegirArchivos: 'Elegir archivos',
            nombreTabla: '',
            idAdjunto: [],
            fileInfos: [],
            otraInst: [],
            loadingdocument: false,
            compressedVideoUrl: null,
        };
    },

    metaInfo() {
        return {
            title: "Hace tu denuncia",
        };
    },

    methods: {
        eliminarDenunciado(index) {
            this.arrayDenunciado.splice(index, 1);
            if(index == 0) {
                this.mostrarDenunciado = false;
            }
        },

        eliminarAdjunto(index) {
            this.fileInfos.splice(index, 1);
        },

        eliminarDenunciante(index) {
            this.arrayDenunciante.splice(index, 1);
            if(index == 0) {
                this.mostrarDenunciante = false;
            }
        },

        // refresh() {
        //     this.arrayDenunciado = [];
        //     this.arrayDenunciante = [];

        //     this.$router.replace("/estado-denuncia");
        // },

        // fileType(tipo) {
        //     if (tipo) {
        //         const extension = tipo.split(".").pop()?.toLowerCase();
        //         return extension;
        //     }
        //     return "No hay archivo seleccionado";
        // },

        async btnFileSelector() {
            this.$refs.fileSelector.click();
        },

        activeProtegerDatos(act) {
            // console.log('Hice clic en activeProtegerDatos', act);
            // console.log("metodo: activeChange_other " + " variable: " + act);
            if (true == act) {
                this.denunciantes.protegerDatos = true;

            }
            if (false == act) {
                this.denunciantes.protegerDatos = false;
            }
            // console.log('denunciantes protegerDatos', this.denunciantes.protegerDatos);
        },

        onFileChange(event) {

            this.loadingdocument = true;
            this.progressInfos = [];
            this.selectedFiles = event.target.files;
            this.elegirArchivos = this.selectedFiles[0].name;
            this.nombreTabla = 'po_denuncias';

            this.message = "";
            for (let i = 0; i < this.selectedFiles.length; i++) {
                
                // if(this.selectedFiles[i].type == 'image/png') { //  cambiar la extensión por mp4
                //     const file = this.selectedFiles[i];
                //     if(!file) return;

                // }

                this.upload(i, this.selectedFiles[i]);
            }
            this.elegirArchivos = 'Elegir archivos';

            // this.loadingdocument = false;

            // Swal.fire({
            //     title: "Archivos cargados correctamente",
            //     icon: "success",
            //     confirmButtonColor: "#356AA0",
            // });

            // if (this.cantArchivo > 3) {
            //     Swal.fire({
            //         title: "Error al subir el archivo",
            //         text: `No se puede subir más de 4 archivos por denuncia.`,
            //         icon: "error",
            //         confirmButtonColor: "#356AA0",
            //     });
            //     return;
            // }
            // const file = event.target.files[0];
            // if (file && file.size > this.maximoPermitido) {
            //     Swal.fire({
            //         title: "Error al subir el archivo",
            //         text: `El archivo seleccionado es demasiado grande. El tamaño máximo permitido es de ${ this.maximoPermitido / 5000000 } MB.`,
            //         icon: "error",
            //         confirmButtonColor: "#356AA0",
            //     });
            //     event.target.value = null; // Limpia el valor del componente de entrada de archivo
            //     this.selectArchivo = null; // Limpia el archivo seleccionado en el modelo de datos
            // } else {
            //     this.cantArchivo = this.cantArchivo + 1;
            //     this.selectArchivo = file;
            // }
            // this.nuevoArchivo();
        },

        upload(idx, file) {
            this.progressInfos[idx] = { percentage: 0, fileName: file.name };
            DenunciaService.uploadFileDenuncia(file, this.nombreTabla, false, (event) => {
                this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
            })
                .then((response) => {
                    // console.log('Response: ' + response.data.id);
                    // var listaHTML = "<b>Se ha subido el archivo correctamente</b> <br />";
                    // for (var i = 0; i < this.progressInfos.length; i++) {
                    //     listaHTML += this.progressInfos[i].fileName + "<br/>";
                    // }
                    // this.mensaje.altaArchivo.html = listaHTML;
                    // this.$swal(this.mensaje.altaArchivo);

                    // // this.message = 'Se ha subido el archivo correctamente';
                    this.idAdjunto.push({ 'id': response.data.id });
                    // console.log('ID adjunto ', this.idAdjunto);

                    //es posible que tenga que incluir la variable nombre en el json, dejo comentado por si
                    //var indiceUltimoSlash = response.data.pathSftp.lastIndexOf("/");
                    //var nombreArchivo = response.data.pathSftp.slice(indiceUltimoSlash + 1);
                    const contentType = response.data.contentType;
                    
                    this.tipoArchivo = this.obtenerDescripcionTipo(contentType);

                    this.fileInfos.push({
                        id: response.data.id,
                        descripcion: response.data.descripcion,
                        url: response.data.pathSftp,
                        contentType: this.tipoArchivo
                    });

                    // console.log('fileInfos: ', this.fileInfos);
                    return this.fileInfos;
                })
                .catch(() => {
                    // this.loadingDocument = false;
                    // console.log('ERRRRROOOORRR');
                    this.progressInfos[idx].percentage = 0;
                    Swal.fire({
                        title: "Error al subir el archivo",
                        text: `Ha ocurrido un error al cargar el archivo.`,
                        icon: "error",
                        confirmButtonColor: "#356AA0",
                    });
            //     return;
                    // this.$swal(this.mensaje.errorArchivo);
                    // this.messageError = "No se pudo cargar el archivo:" + file.name;
                })
                .finally(() => {
                    this.progressInfos[idx].percentage = 0;
                    this.loadingdocument = false;
                });
        },

        obtenerDescripcionTipo(contentType) {
            const tipos = {
                "application/pdf": "Documento PDF",
                "image/jpeg": "Imagen JPG",
                "image/png": "Imagen PNG",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "Documento Word",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Hoja de cálculo Excel",
                "application/zip": "Archivo ZIP",
                "application/msword": "Documento Word",
                "application/vnd.ms-excel": "Hoja de cálculo Excel",
                "audio/mpeg": "Audio MP3",
                "video/mp4": "Video MP4",
            };

            return tipos[contentType] || "Tipo desconocido";
        },

        enviar() {

            this.loading = true;

            if(this.arrayDenunciante.length == 0) {
                this.loading = false;
                this.$swal({
                    title: "Mensaje",
                    html: "Favor cargar al menos un denunciante!!", //No hay denunciante, favor cargar!!",
                    icon: "warning",
                    confirmButtonColor: "#356AA0",
                });
                // console.log('No hay denunciante');
                return;
            } else if(this.arrayDenunciado.length == 0) {
                this.loading = false;
                this.$swal({
                    title: "Mensaje",
                    html: "Favor cargar al menos un denunciado!!", //No hay denunciado, favor cargar!!",
                    icon: "warning",
                    confirmButtonColor: "#356AA0",
                });
                // console.log('No hay denunciado');
                return;
            } else if(!this.denuncia.fechaDeLosHechos) {
                this.loading = false;
                this.$swal({
                    title: "Mensaje",
                    html: "La fecha de los hechos es obligatoria, favor cargar!!",
                    icon: "warning",
                    confirmButtonColor: "#356AA0",
                });
                // console.log('No hay fecha de los hechos');
                return;
            } else if(!this.denuncia.descripcion) {
                this.loading = false;
                this.$swal({
                    title: "Mensaje",
                    html: "Favor cargar la descripcion de los hechos!!", //No hay denunciante, favor cargar!!",
                    icon: "warning",
                    confirmButtonColor: "#356AA0",
                });
                // console.log('No hay descripcion de los hechos');
                return;
            } else if(this.denunciaOtraInstitucion == true && !this.denuncia.otraInstitucion) {
                this.loading = false;
                this.$swal({
                    title: "Mensaje",
                    html: "Favor cargar la otra institucion!!", //No hay denunciante, favor cargar!!",
                    icon: "warning",
                    confirmButtonColor: "#356AA0",
                });
                // console.log('No hay otra institucion');
                return;
            }


            if(this.denuncia.otraInstitucion) {
                this.otraInst = [{
                    'institucion': this.denuncia.otraInstitucion
                }]
            } else {
                this.otraInst = [{
                    'institucion': ''
                }]
            }
            

            this.formEnviar = {
                departamento: this.denuncia.departamento,
                ciudad: this.denuncia.ciudad,
                barrio: this.denuncia.barrio,
                direccion: this.denuncia.direccion,
                hechos: this.denuncia.descripcion,
                esHechoPuniblePenal: this.denuncia.esHechoPuniblePenal,
                esHechoPunibleAdministrativo: this.denuncia.esHechoPunibleAdministrativo,
                fechaHechos: this.denuncia.fechaDeLosHechos,
                denunciaAnteOtraInst: this.denuncia.denunciaOtraInstitucion,
                listDenunciante: this.arrayDenunciante,
                listDenunciado: this.arrayDenunciado,
                listDenunciadoEn: this.otraInst,
                listaDocAdjunto: this.idAdjunto
            }

            DenunciaService.saveDenuncia(this.formEnviar)
                .then(response => {
                    // console.log('Este es el resultado: ', response.data);
                    
                    this.limpiarDenuncia();
                    this.limpiarDenunciantes();
                    this.limpiarDenunciados();

                    this.loading = false;
                    
                    Swal.fire({
                        title: "¡Tu denuncia fue enviada correctamente!",
                        html: `<p style="font-size: 15px;">Recuerda guardar el código único generado para tu denuncia con el fin de poder realizar seguimiento efectivo de la misma.</p>
                                <p style="color: red; font-size: 15px;">Si olvidas este número no podrás ver el proceso ni los resultados de tu denuncia, te recomendamos descargar un archivo con el número de comprobante.</p>
                                <h3>${ response.data }</h3>`,
                        icon: "success",
                        confirmButtonColor: "#356AA0",
                    });
                },
                error => {
                    if (error.response && error.response.status === 403) {
                        console.log('ERROR response, 403');
                    }else if (error.response && error.response.status === 401) {
                        console.log('ERROR response, 401');
                    }
                });

            this.limpiarDenuncia();
            this.limpiarDenunciantes();
            this.limpiarDenunciados();
        },

        async convertirArchivoABase64(file) {
            // console.log('METODO CONVERTTOBASE64 EL FILE ES: ', file);
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64doc = reader.result.split(',')[1];
                    resolve(base64doc);
                };

                reader.onerror = (error) => {
                    reject(error);
                }

                // if (file.type.includes('video/mp4') || file.name.endsWith('.mp4')) {
                //     reader.readAsDataURL(file);
                // } else if (file.type.includes('image/png') || file.type.includes('image/jpeg') || file.name.endsWith('.png') || file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')) {
                //     reader.readAsDataURL(file);
                // } else {
                //     // Maneja otros tipos de archivos según sea necesario
                //     reject(new Error("Tipo de archivo no compatible."));
                // }

                reader.readAsDataURL(file);
            });
        },
        
        //crear archivo y enviar peticionSOAP
        async convertirYEnviar() {
            this.loading = true;
            try {
                const base64data = await this.download();
                await this.enviarSOAP(base64data);
            } catch (error) {
                // console.error("Error en la operación completa:", error);
                this.loading = false;
            }
        },
        
        // decargar pdf base 64
        async download() {
            try {
                // console.log("descargando!!");
                const el = document.getElementById("vue3SimpleHtml2pdf"); //ojo con la ref y el byId
                if (!el) {
                    return;
                }
                //habilitar bajar el archivo
                // html2pdf().from(el).set(this.pdfOptions).save(this.exportFilename);
                // this.iFrame = await html2pdf().from(el).output('bloburl');

                const blobArchive = await html2pdf().from(el).output("blob");
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    reader.onload = function () {
                        const base64data = reader.result.split(",")[1];
                        // console.log("PDF en base64:", base64data);
                        resolve(base64data);
                    };
                    reader.onerror = function (error) {
                        reject(error);
                    };
                    reader.readAsDataURL(blobArchive);
                    // console.log(this.blobArchive);
                });
            } catch (error) {
                console.error("Error durante la conversión a base64:", error);
                throw error;
            }
        },

        async enviarSOAP(base64data) {
            try {
                if(this.denuncia.denunciaAnonima == true) {
                    this.asunto = "Denuncia Anónima Vía Portal";
                    this.expediente = 108; // Anonima
                    this.denunciante = '';
                } else if (this.denuncia.denunciaAnonima == false) {
                    this.asunto = "Denuncia Nominal Vía Portal";
                    this.expediente = 110; //Nominal
                    this.nombreAsunto = '';
                    this.denunciante = this.arrayDenunciante[0].nombreApellido + 'y/o otros';
                }

                let varSolicitudSOAP = `
                    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="http://www.apiasolutions.com/WebServices">
                        <soapenv:Header>
                            <web:SOAPAction>ws_generacion_expediente_extendido</web:SOAPAction>
                        </soapenv:Header>
                        <soapenv:Body>
                            <web:ws_generacion_expediente_extendido>
                                <web:ApiaWSInput>                                                                                       
                                    <web:B_PRMT_OFICINA_ORIGEN_EXPEDIENTE>31</web:B_PRMT_OFICINA_ORIGEN_EXPEDIENTE>
                                    <web:B_PRMT_TIPO_EXPEDIENTE>` + this.expediente + `</web:B_PRMT_TIPO_EXPEDIENTE>
                                    <web:B_PRMT_ASUNTO_EXPEDIENTE>` + this.asunto + `/` + this.denunciante + `</web:B_PRMT_ASUNTO_EXPEDIENTE>
                                    <web:B_PRMT_PRIORIDAD_EXPEDIENTE>1</web:B_PRMT_PRIORIDAD_EXPEDIENTE>
                                    <web:B_PRMT_CONFIDENCIALIDAD_EXPEDIENTE>1</web:B_PRMT_CONFIDENCIALIDAD_EXPEDIENTE>
                                    <web:B_PRMT_DOCUMENTACION_FISICA_EXPEDIENTE>1</web:B_PRMT_DOCUMENTACION_FISICA_EXPEDIENTE>
                                    <web:B_PRMT_CLASIFICACION_EXPEDIENTE>1</web:B_PRMT_CLASIFICACION_EXPEDIENTE>`;
                                
                                varSolicitudSOAP +=
                                    `<web:B_archivoAdjunto>` + base64data + `</web:B_archivoAdjunto>`;

                
                if (this.arrayFile.length > 0) {
                    // console.log('Entre en el IF, por ende hay un archivo adjunto o más');
                    // console.log('Este es el arrayFile: ', this.arrayFile);
                    // console.log('Este es el arrayFile Length: ', this.arrayFile.length);
                    for (let i = 0; i < this.arrayFile.length; i++) {
                        let pos = [i];
                        // console.log('Posicion para usar: ', pos);
                        // console.log('Entro en el FOR: ', i);

                        const archivo = this.arrayFile[i].selectArchivo;
                        const base64doc = await this.convertirArchivoABase64(archivo);
                        // console.log('Este es el archivo elegido: ', archivo);
                        // console.log('Este es el archivo convertido: ', base64doc);

                        if(pos == 0) {
                            // console.log('Este tiene que ser el dos');
                            varSolicitudSOAP +=
                                    `<web:B_archivoAdjuntoDos>` + base64doc + `</web:B_archivoAdjuntoDos>`;
                        } else if(pos == 1) {
                            // console.log('Este tiene que ser el tres');
                            varSolicitudSOAP +=
                            `<web:B_archivoAdjuntoTres>` + base64doc + `</web:B_archivoAdjuntoTres>`;
                        } else if(pos == 2) {
                            // console.log('Este tiene que ser el cuatro');
                            varSolicitudSOAP +=
                            `<web:B_archivoAdjuntoCuatro>` + base64doc + `</web:B_archivoAdjuntoCuatro>`;
                        } else if(pos == 3) {
                            // console.log('Este tiene que ser el cinco');
                            varSolicitudSOAP +=
                            `<web:B_archivoAdjuntoCinco>` + base64doc + `</web:B_archivoAdjuntoCinco>`;
                        }

                    }

                    varSolicitudSOAP += `</web:ApiaWSInput>
                            </web:ws_generacion_expediente_extendido>
                        </soapenv:Body>
                    </soapenv:Envelope>`;

                } else {
                    varSolicitudSOAP += `</web:ApiaWSInput>
                                </web:ws_generacion_expediente_extendido>
                            </soapenv:Body>
                        </soapenv:Envelope>`;
                }
                const solicitudSOAP = varSolicitudSOAP;

                // console.log('Resultado SOAP: ', solicitudSOAP);

                // const response = await api.post(
                const response = await axios.post(
                    "ApiaWSws_generacion_expediente_extendido",
                    // "http://10.168.100.81:8080/Apia/services/ApiaWSws_generacion_expediente_extendido",
                    solicitudSOAP,
                    {
                        baseURL: "/", // Establece la URL base del servidor proxy
                        headers: {
                            "Content-Type": "text/xml",
                            SOAPAction: "ws_generacion_expediente_extendido",
                        },
                    }
                );
                // console.log(response.data);
                const respuestaXML = response.data;
                xml2js.parseString(respuestaXML, (err, result) => {
                    if (err) {
                        // console.error('Error al analizar XML:', err);
                        return;
                    }
                    // const expedienteStr = result["soapenv:Envelope"]["soapenv:Body"][0]["ws_generacion_expediente_extendido_response"][0]["ws_generacion_expediente_extendido_result"][0];
                    const expedienteStr = result["soapenv:Envelope"]["soapenv:Body"][0]["ws_generacion_expediente_extendidoResponse"][0]["ExecResult"][0]['parameters'][0]['PRMT_NRO_EXPEDIENTE_STR'][0];
                    this.resultado = expedienteStr;
                    // console.log('El expediente es: ', this.resultado);
                    this.loading = false;

                    Swal.fire({
                        title: "¡Tu denuncia fue enviada de forma correcta!",
                        html: `<p style="font-size: 15px;">Recuerda guardar el código único generado para tu denuncia con el fin de poder realizar seguimiento efectivo de la misma.</p>
                                <p style="color: red; font-size: 15px;">Si olvidas este número no podrás ver el proceso ni los resultados de tu denuncia, te recomendamos descargar un archivo con el número de comprobante.</p>
                                <h3>${ this.resultado }</h3>`,
                        icon: "success",
                        confirmButtonColor: "#356AA0",
                    });
                    
                    this.limpiarDenuncia();
                    this.limpiarDenunciantes();
                    this.limpiarDenunciados();


                });

            } catch (error) {
                // console.error("Error en downloadAndAnotherMethod:", error);
                this.loading = false;
                // Maneja el error de acuerdo a tus necesidades
            }
        },

        cerrarModal() {
            // const myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'));
            // myModal.hide();
        },

        isRequired(value) {
            if (!value) {
                // console.log("Este campo es requerido");
                return "Este campo es requerido";
            }
            return true;
        },

        validateEmail(value) {
            if (!value) {
                return "Este campo es requerido";
            }

            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return "Este campo debe ser un correo";
            }
            return true;
        },

        // async crear() {
        crear() {
            // let that = this;
            // console.log('Array Denunciado: ', this.arrayDenunciado);
            // console.log('Array Denunciante: ', this.arrayDenunciante);
            this.denuncia.denunciados = this.arrayDenunciado;
            this.denuncia.denunciantes = this.arrayDenunciante;
            // console.log('hola mundo');
            // console.log('Array Denunciante: ' + this.denuncia.denunciantes);
            this.denuncia.archivos = this.arrayFile;
            
            // console.log(
                // "estos son los datos de la denuncia -----> " +
                // this.denuncia.tipoDenuncia
            // );
            // console.log('Estos son los datos de la denuncia; ' + this.denuncia);
            Object.entries(this.denuncia).forEach(([key, value]) => {
                console.log(`${key}: ${value}`);

                if (Array.isArray(value)) {
                    // console.log("denunciados es un array.");
                    Object.entries(value).forEach(([key, value]) => {
                        console.log(`${key}: ${value.nombreApellido}`);
                    });
                }
            });
            // for (const key in this.denuncia) {
            //   if (Object.prototype.hasOwnProperty.call(this.denuncia, key)) {
            //     console.log(`${key}: ${this.denuncia[key]}`);
            //   }
            // }
            // this.archivos = this.arrayFile

            // this.carga_comprobante = false;

            // this.denuncia_response = await this.crearDenuncia(this.denuncia).then(resp => resp)

            // // this.denuncia_response = { comprobante: '6B105498', uid: '9bc60ab3-cd9f-4f27-b157-0258a322e476' }

            // let uid = this.denuncia_response.uid

            // Promise.all(this.archivos.map((archivo) => {
            //   return this.uploadFile(archivo.selectArchivo, uid);
            // })).then((fileUploaded) => {
            //   console.log(fileUploaded);
            // });

            // this.carga_comprobante = true;
        },

        uploadFile(archivo, uid) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                const binary = reader.readAsDataURL(archivo);
                console.log(binary.toString());

                const formData = new FormData();
                formData.append("file", archivo, archivo.name);
                let headers = {
                    "Content-Type": "multipart/form-data",
                    accept: "*/*",
                };

                const fileData = { uid: uid, formData: formData, headers: headers };
                resolve(this.subirArchivo(fileData));
                console.log(reject);
            });
        },

        actualizarMun() {
            this.denuncia.ciudad = "";
            this.actualizarBar();
            this.$nextTick(() => {
                this.denuncia.barrio = "";
            })
            // console.log('ActualizarMUN')
        },

        actualizarBar() {
            this.denuncia.barrio = "";
            // console.log('ActualizarBAR')
            // this.setBarrios();
            // this.bar[''] = '';
            // this.bar = [''];
        },

        seleccionOtraOEE({ id, text }) {
            console.log("metodo seleccionOtraOEE --->" + id + text);
        },

        guardarCodigo() {
            window.print();
        },

        copiarCodigo() {
            this.copiado = false;
            navigator.clipboard.writeText(
                (this.copiar_comprobante = this.denuncia_response.comprobante)
            );
            this.copiado = true;
        },

        funcionarioBoolean(event) {
            if (event.target.value === "si") {
                this.denunciados.esFuncionario = true;
            } else if (event.target.value === "no") {
                this.denunciados.esFuncionario = false;
            }
        },

        imprimir() {},

        botonDenuncia() {
            if (
                this.denunciantes.nombreApellido == "" &&
                this.denunciantes.tipoDocumento == "" &&
                this.denunciantes.nroDocumento == "" &&
                this.denunciantes.domicilio == "" &&
                this.denunciantes.email == "" &&
                this.denunciantes.telefono == "" &&
                this.denunciantes.sexo == "" &&
                this.denunciados.institucion == "" && // en denunciados solo la institución es requerida
                this.denuncia.fechaDeLosHechos == "" &&
                this.denuncia.descripcion == ""
                // this.denuncia.denunciaOtraInstitucion == ""
                // otraInstitucion
            ) {
                this.vacio = false;
            } else {
                this.vacio = true;
            }
        },

        limpiarDenunciantes() {
            (this.denunciantes.nombreApellido = ""),
            (this.denunciantes.tipoDocumento = ""),
            (this.denunciantes.nroDocumento = ""),
            (this.denunciantes.domicilio = ""),
            (this.denunciantes.email = ""),
            (this.denunciantes.telefono = ""),
            (this.denunciantes.sexo = ""),
            (this.denunciantes.protegerDatos = false);
        },

        limpiarDenunciados() {
            (this.denunciados.nombreApellido = ""),
            (this.denunciados.tipoDocumento = ""),
            (this.denunciados.nroDocumento = ""),
            (this.denunciados.institucion = ""),
            (this.denunciados.cargo = ""),
            (this.denunciados.sexo = "");
        },

        limpiarDenuncia() {
            this.denuncia.uid = "";
            this.denuncia.comprobante = "";
            this.denuncia.tipoDenuncia = "rendiciones";
            this.denuncia.denunciaAnonima = true;
            this.denuncia.denunciaOtraInstitucion =  false;
            this.denuncia.otraInstitucion = "";
            this.denuncia.fechaDeLosHechos = "";
            this.denuncia.institucionDenunciada = "";
            this.denuncia.otraInstitucionDenunciada = null;
            this.denuncia.direccion = "";
            this.denuncia.barrio = "";
            this.denuncia.ciudad = "";
            this.denuncia.departamento = "";
            this.denuncia.descripcion = "";
            this.denuncia.urlDocumentoAdjunto = "";
            this.denuncia.pretension = "";
            this.denuncia.estado = null;
            this.denuncia.administradoPorUsuario = null;
            this.denuncia.fechaCreacion = new Date();
            this.denuncia.denunciados = [];
            this.denuncia.denunciantes = [];
            this.denuncia.archivos = [];
            this.denuncia.base64data = "";
            this.denuncia.esHechoPuniblePenal = true;
            this.denuncia.esHechoPunibleAdministrativo = false;
            this.denuncia.denunciaAnteOtraInst = "";
            this.arrayDenunciado = [];
            this.arrayDenunciante = [];
            this.arrayFile = [];
        },

        // ...mapMutations('apiArchivos', ['nuevoArchivo']),

        // ...mapActions('apiDenuncias', ['crearDenuncia', 'obtenerInstituciones']),

        // ...mapActions('apiArchivos', ['subirArchivo']),

        activeType(active) {
          //console.log("elegí: " + active);
            if (active == "rendiciones") {
                (this.denuncia.tipoDenuncia = "Rendiciones de cuentas en concepto de FONACIDE y Royalties"),
                (this.active_rendiciones = true);
                (this.active_presentacion = false),
                (this.active_licitaciones = false),
                (this.active_manejo = false),
                (this.active_funcionarios = false),
                (this.active_otros = false);
            } else if (active == "presentacion") {
                (this.denuncia.tipoDenuncia = "Presentación o no de las Declaraciones Juradas"),
                (this.active_presentacion = true);
                (this.active_rendiciones = false),
                (this.active_licitaciones = false),
                (this.active_manejo = false),
                (this.active_funcionarios = false),
                (this.active_otros = false);
            } else if (active == "licitaciones") {
                (this.denuncia.tipoDenuncia = "Licitaciones Públicas"),
                (this.active_licitaciones = true);
                (this.active_rendiciones = false),
                (this.active_presentacion = false),
                (this.active_manejo = false),
                (this.active_funcionarios = false),
                (this.active_otros = false);
            } else if (active == "manejo") {
                (this.denuncia.tipoDenuncia = "Manejo indebido del Bien Público"),
                (this.active_manejo = true);
                (this.active_rendiciones = false),
                (this.active_presentacion = false),
                (this.active_licitaciones = false),
                (this.active_funcionarios = false),
                (this.active_otros = false);
            } else if (active == "funcionarios") {
                (this.denuncia.tipoDenuncia = "Contra Funcionarios de la CGR"),
                (this.active_manejo = false);
                (this.active_rendiciones = false),
                (this.active_presentacion = false),
                (this.active_licitaciones = false),
                (this.active_funcionarios = true),
                (this.active_otros = false);
            } else if (active == "otros") {
                (this.denuncia.tipoDenuncia = "Otros"), (this.active_otros = true);
                (this.active_rendiciones = false),
                (this.active_presentacion = false),
                (this.active_licitaciones = false),
                (this.active_funcionarios = false),
                (this.active_manejo = false);
            }
        },

        tooggleShowForm(anonymous) {
            // console.log("metodo: tooggleShowForm " + " variable: " + anonymous);
            if (true == anonymous) {
                this.showForm = false;
                this.denuncia.denunciaAnonima = true;
            }
            if (false == anonymous) {
                this.showForm = true;
                this.denuncia.denunciaAnonima = false;
            }
        },

        activeChange(act) {
            if (true == act) {
                this.denunciantes.protegerDatos = true;
            }
            if (false == act) {
                this.denunciantes.protegerDatos = false;
            }
        },

        activeChange_other(act) {
            // console.log("metodo: activeChange_other " + " variable: " + act);
            if (true == act) {
                this.denuncia.denunciaOtraInstitucion = true;
            }
            if (false == act) {
                this.denuncia.denunciaOtraInstitucion = false;
            }
        },

        checkLength() {
            if (this.denuncia.descripcion.length > this.maxLength) {
                this.denuncia.descripcion = this.denuncia.descripcion.slice(0, this.maxLength);
            }
        },
        checkLengthTwo() {
            if (this.denuncia.pretension.length > this.maxLength) {
                this.denuncia.pretension = this.denuncia.pretension.slice(0, this.maxLength);
            }
        },

        nuevoDenunciado() {
            if (this.carga_denunciados) {
                this.arrayDenunciado.shift();
            }

            if (
                !(
                    // this.denunciados.nombreApellido == "" &&
                    // this.denunciados.tipoDocumento == "" &&
                    // this.denunciados.nroDocumento == "" &&
                    this.denunciados.institucion == ""
                    // this.denunciados.cargo == "" &&
                    // this.denunciados.sexo == ""
                )
            ) {
                // if (!(this.denunciados.nombreApellido.trim() == "")) {
                    this.arrayDenunciado.push({
                        nombreApellido: this.denunciados.nombreApellido,
                        tipoDocumento: this.denunciados.tipoDocumento,
                        nroDocumento: this.denunciados.nroDocumento,
                        institucion: this.denunciados.institucion,
                        cargo: this.denunciados.cargo,
                        sexo: this.denunciados.sexo,
                        esEliminado: this.denunciados.esEliminado,
                        denunciaUid: this.denunciados.denunciaUid,
                    });
                // }

                this.carga_denunciados = false;
                this.mostrarDenunciado = true;
            } else {
                this.$swal({
                    title: "Mensaje",
                    html: "La institución es requerida !!",
                    icon: "warning",
                    confirmButtonColor: "#356AA0",
                });
            }
        },
        nuevoDenunciante() {
            if (this.carga_denunciantes) {
                this.arrayDenunciante.shift();
            }

            if (
                !(
                    this.denunciantes.nombreApellido == "" ||
                    this.denunciantes.tipoDocumento == "" ||
                    this.denunciantes.nroDocumento == "" ||
                    this.denunciantes.domicilio == "" ||
                    this.denunciantes.email == "" ||
                    this.denunciantes.telefono == "" ||
                    this.denunciantes.sexo == ""
                )
            ) {
                if(this.denunciantes.email) {
                    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!regex.test(this.denunciantes.email)) {
                        this.$swal({
                            title: "Mensaje",
                            html: "El correo ingresado no es válido !!",
                            icon: "warning",
                            confirmButtonColor: "#356AA0",
                        });
                    } else if (!/^\d+$/.test(this.denunciantes.telefono)) {
                        this.$swal({
                            title: "Mensaje",
                            html: "Ingrese solo números en el campo teléfono !!",
                            icon: "warning",
                            confirmButtonColor: "#356AA0",
                        });
                    } else {

                        this.arrayDenunciante.push({
                            nombreApellido: this.denunciantes.nombreApellido,
                            tipoDocumento: this.denunciantes.tipoDocumento,
                            nroDocumento: this.denunciantes.nroDocumento,
                            domicilio: this.denunciantes.domicilio,
                            email: this.denunciantes.email,
                            telefono: this.denunciantes.telefono,
                            sexo: this.denunciantes.sexo,
                            protegerDatos: this.denunciantes.protegerDatos,
                            esEliminado: this.denunciantes.esEliminado,
                            denunciaUid: this.denunciantes.denunciaUid,
                        });
                        this.carga_denunciantes = false;
                        this.mostrarDenunciante = true;
                        this.limpiarDenunciantes();
                    
                    }
                }

            } else {
                this.$swal({
                    title: "Mensaje",
                    html: "Todos los datos del denunciante son <strong>requeridos</strong> !!",
                    icon: "warning",
                    confirmButtonColor: "#356AA0",
                });
            }
        },

        nuevoArchivo() {
            if (this.selectArchivo) {

                this.arrayFile.push({
                    selectArchivo: this.selectArchivo,
                    base64: this.base64,
                });
            }
        },

        setDepartamentos() {
            JSONService.getDataDepartamento().then((response) => {
                this.deptos = response[0].departamento.map((departamento) => {
                    return {
                        text: departamento.nombre,
                        id: departamento.nombre,
                    };
                });
            });
        },
        setMunicipios() {
            // Llamar al servicio JSON y obtener datos de municipios
            JSONService.getDataMunicipio().then((response) => {
                // Transformar la estructura de datos obtenida del servicio JSON
                this.mun = response[0].municipio;
                // const numMunicipios = Object.keys(this.mun).length;
                // console.log("Número de municipios:", numMunicipios);
                // Mostrar todos los datos de mun en la consola
                // for (const municipio in this.mun) {
                //     if (Object.prototype.hasOwnProperty.call(this.mun, municipio)) {
                //         // const ciudades = this.mun[municipio].join(', ');
                //         // console.log(`${municipio}: ${ciudades}`);
                //     }
                // }
            });
        },
        setBarrios() {
            JSONService.getDataBarrio().then((response) => {
                this.bar = response[0].barrio;
                // for (const barrio in this.bar) {
                //     if (Object.prototype.hasOwnProperty.call(this.bar, barrio)) {
                //         // const ciudades = this.mun[municipio].join(', ');
                //         // console.log(`${municipio}: ${ciudades}`);
                //     }
                // }
            });
        },
        setOEE() {
            JSONService.getDataOEE().then((response) => {
                this.instituciones = response[0].institucion.map((institucion) => {
                    return {
                        text: institucion.nombre,
                        id: institucion.nombre,
                    };
                });
            });
        },
    },
    mounted() {
        this.setDepartamentos();
        this.setMunicipios();
        this.setBarrios();
        this.setOEE();
    },

    computed: {
        // ...mapState('apiDenuncias', ['entradas', 'entrada']),
        // ...mapState('apiArchivos', ['archivosArray']),

        remainingChars() {
            return this.maxLength - this.denuncia.descripcion.length;
        },

        remainingCharsTwo() {
            return this.maxLength - this.denuncia.pretension.length;
        },

        municipiosFiltrados() {
            // console.log(
                // "COMPUTED municipiosFiltrados: " + this.denuncia.departamento
            // );
            // console.log('municipiosFiltrados');
            if (this.denuncia.departamento) {
                return this.mun[this.denuncia.departamento];
            } else {
                return [];
            }
        },

        barriosFiltrados() {
            // console.log('barriosFiltrados');
            if (this.denuncia.ciudad) {
                return this.bar[this.denuncia.ciudad];
            } else {
                return [];
            }
        },

        nombreInstituciones() {
            return this.instituciones.map(
                //@ts-ignore
                (institucion) => institucion.nombre
            );
        },

        otrosSelected() {
            return this.denuncia.institucionDenunciada === "Otros";
        },
    },

    watch: {
        "denuncia.institucionDenunciada"(newV, preV) {
            if (newV !== "OTRA INSTITUCIÓN") {
                // console.log("se eligio uns institución!");
                this.denuncia.otraInstitucionDenunciada = null;
                this.isOtraInstitucion = false;
                console.log(preV);
            } else {
                this.isOtraInstitucion = true;
            }
        },
        "denuncia.ciudad"(newVal) {
            if (!newVal) {
                this.denuncia.barrio = "";
            }
        },
        "denuncia.departamento"(newVal) {
            if (!newVal) {
                this.denuncia.barrio = "";
            }
        }
    },

    created() {
        // this.arrays()
        // this.obtenerInstituciones().then(res => {
        //   this.instituciones = res
        // })//.catch(error => console.log())
        // this.$store.dispatch('goToTop')
    },
};
</script>

<style>
.pasos-form-denuncias .active {
    padding: 20px;
    background: #356aa0;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    color: #fff;
}

.pasos-form-denuncias .inactive {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
}

.active-btn-denuncia-yes {
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 13px 70px;
    background-color: #356aa0;
    border-radius: 8px 0px 0px 8px;
    box-shadow: 0px 3px 6px #00000029;
}

.active-btn-denuncia-no {
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 13px 70px;   
    background-color: #356aa0;
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0px 3px 6px #00000029;
}

.btn-yes {
    border: none;
    font-size: 18px;
    color: #356aa0;
    font-weight: bold;
    padding: 13px 70px;
    background-color: #fff;
    border-radius: 8px 0px 0px 8px;
    box-shadow: 0px 3px 6px #00000029;
}

.btn-no {
    border: none;
    font-size: 18px;
    color: #356aa0;
    font-weight: bold;
    padding: 13px 70px;
    background-color: #fff;
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0px 3px 6px #00000029;
}

.color-text {
    color: #356aa0;
}

@media (max-width: 400px) {
    .btn-yes {
        padding: 13px 50px !important;
    }

    .btn-no {
        padding: 13px 50px !important;
    }

    .active-btn-denuncia-yes {
        padding: 13px 50px;
    }

    .active-btn-denuncia-no {
        padding: 13px 50px;
    }

    .readonly-input {
        border: none;
        background-color: transparent;
        outline: none;
    }
}

.loader {
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
</style>