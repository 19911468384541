<template>
    <header id="header" class="d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">
            <router-link to="/" class="logo">
                <img src="assets/img/logo/logo.png" alt="">
            </router-link>

            <!-- Botón de menú móvil -->
            <button class="mobile-nav-toggle" @click="toggleMobileMenu">
                <i :class="isMobileMenuOpen ? 'bi bi-x' : 'bi bi-list'"></i>
            </button>
            <nav id="navbar" class="navbar" :class="{ 'mobile-nav-active': isMobileMenuOpen }">
                <ul>
                    <li><router-link to="/" class="nav-link scrollto" :class="{ active: $route.path === '/' }" @click="closeMobileMenu">INICIO</router-link></li>
                    <li><router-link to="/denuncia" class="nav-link scrollto" :class="{ active: $route.path === '/denuncia' }" @click="closeMobileMenu">HACE TU DENUNCIA</router-link></li>
                    <li><router-link to="/consulta" class="nav-link scrollto" :class="{ active: $route.path === '/consulta' }" @click="closeMobileMenu">CONSULTA TU DENUNCIA</router-link></li>
                    <li class="dropdown">
                        <router-link to="nosotros" class="nav-link scrollto" :class="{ active: $route.path === '/nosotros' }"><span>NOSOTROS</span> <i class="bi bi-chevron-down"></i></router-link>
                        <ul>
                            <li><router-link to="/nosotros" @click="closeMobileMenu">QUIÉNES SOMOS</router-link></li>
                            <li><router-link to="/nosotros" @click="closeMobileMenu">CÓMO TRABAJAMOS</router-link></li>
                            <li><router-link to="/nosotros" @click="closeMobileMenu">PREGUNTAS FRECUENTES</router-link></li>                   
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
export default {
    name: 'vHeader',
    data() {
        return {
            isMobileMenuOpen: false
        }
    },

    methods: {
        toggleMobileMenu() {
            console.log('toggleMobileMenu ejecutado'); // Verifica si la función se ejecuta
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
        
        closeMobileMenu() {
            this.isMobileMenuOpen = false;
        }
    }
}
</script>

<style scoped>
/* Estilos generales */
#header {
    position: relative;
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

/* Estilos del botón hamburguesa */
.mobile-nav-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 1100;
}

/* Estilos del menú móvil */
@media (max-width: 768px) {
    .mobile-nav-toggle {
        display: block;
        transform: translateY(0);
    }

    .navbar {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 1000;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
    }

    .navbar.mobile-nav-active {
        transform: translateY(0);
    }

    .navbar ul {
        flex-direction: column;
        padding: 20px;
        text-align: center;
    }

    .navbar ul li {
        padding: 10px 0;
    }
}
</style>