<template>
    <footer id="footer">
        
        <div class="footer-top">
            <div class="container">
                <div class="row">

                    <div class="col-lg-4 col-md-6 footer-contact">
                        <router-link to="/" class="logo"><img src="assets/img/logo/logo.png" alt=""></router-link>
                    </div>

                    <div class="col-lg-4 col-md-6 footer-links">
                        <h4>Nosotros</h4>
                        <ul>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="/nosotros">¿Quiénes somos?</router-link></li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="/nosotros">¿Cómo trabajamos?</router-link></li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="/nosotros">Preguntas frecuentes</router-link></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>Contactos</h4>
                        <div class="social-links mt-3">
                            <i class="bx bx-phone-call"></i>
                            +595 21 6200 436
                            <br>
                            <i class="bx bx-map"></i>
                            Bruselas N° 1880
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="container py-4">
            <div style="text-align: center;">
                &copy; 2023 Contraloría General de la República
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'vFooter'
}
</script>