<template>
    <section id="pf" class="frequent-questions py-5">
        <h2 class="text-center mb-5">Preguntas frecuentes</h2>
        <div class="container-accordion col-xl-8 col-md-9 col-10 m-auto">
            <ul class="accordion accordion-flush p-0" id="accordionFlushExample">
                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-one-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-one-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    <b>1. ¿Quiénes pueden realizar una denuncia ciudadana?</b>
                                </div>
                            </button>
                        </h2>
                        <div id="question-one-collapse" class="accordion-collapse collapse" aria-labelledby="question-one-heading"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">Toda persona mayor de edad que tiene conocimiento de un supuesto hecho de corrupción cometido por un funcionario público en el ejercicio de sus funciones puede denunciar a través del Portal Nacional de Denuncias Ciudadanas, o por escrito o verbalmente en la Dirección General de Integridad y Transparencia (DGIT).</p>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-two-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-two-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    <b>2. ¿Cuáles son las modalidades de las denuncias ciudadanas? (nominales o con protección de datos)</b>
                                </div>
                            </button>
                        </h2>
                        <div id="question-two-collapse" class="accordion-collapse collapse" aria-labelledby="question-two-heading"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">Las modalidades de registro de las denuncias podrán ser nominales o con protección de datos.</p>
                                <br>
                                <p class="m-0">Las denuncias con protección de datos implican la carga de información respecto a la identidad del denunciante en el Portal Nacional de Denuncias Ciudadana, no obstante, una vez culminado el registro de la denuncia, el sistema informático cifrará los datos de manera automática.</p>
                                <!-- <a class="m-0" href="https://denuncias.contraloria.gov.py/api/archivos/public/obtenerArchivo?fullPath=files/Documentos/Res._CGR_N_269-2022.pdf">Descargar Resolución CGR N° 269/2022</a> -->
                            </div>
                        </div>
                    </div>
                </li>

                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-three-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-three-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    <b>3. ¿Qué puedo denunciar?</b>
                                </div>
                            </button>
                        </h2>
                        <div id="question-three-collapse" class="accordion-collapse collapse"
                            aria-labelledby="question-three-heading" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">Toda conducta irregular con relevancia penal o que implique la violación de normas administrativas cometidos por funcionarios públicos en el ejercicio de sus funciones.</p>
                                <br>
                                <p class="m-0">La corrupción se da cuando un funcionario público abusa de su poder o autoridad para obtener beneficios personales o para terceros, en detrimento del bien común y de la confianza pública. Esta corrupción puede manifestarse a través de sobornos, malversación de fondos públicos, tráfico de influencias, fraude, entre otras prácticas indebidas.</p>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-four-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-four-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    <b>4. ¿Qué datos debo consignar en mi denuncia?</b>
                                </div>
                            </button>
                        </h2>
                        <div id="question-four-collapse" class="accordion-collapse collapse"
                            aria-labelledby="question-four-heading" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">1. Identificación del denunciante: Nombres y apellidos, número de documento de identidad, domicilio particular o laboral, correo electrónico y número de teléfono particular o celular.</p>
                                <p class="m-0">2. Identificación del denunciado: Nombres y apellidos, número de documento de identidad, en caso de que se conozca, institución, cargo o función que desempeña o desempeñaba.</p>
                                <p class="m-0">3. Descripción del hecho: Relato claro y detallado del o los hechos denunciados, proporcionando fecha y lugar del acontecimiento, así como cualquier otra circunstancia relevante.</p>
                                <p class="m-0">4. Elementos probatorios: En caso de que el denunciante cuente con documentos de respaldo o cualquier otro elemento que sustente su denuncia, deberán ser acompañados al momento del registro.</p>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-five-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-five-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    <b>5. ¿Cuál es el plazo de finalización de la gestión de la denuncia ciudadana?</b>
                                </div>
                            </button>
                        </h2>
                        <div id="question-five-collapse" class="accordion-collapse collapse"
                            aria-labelledby="question-five-heading" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">La DGIT tendrá un plazo de 30 (treinta) días hábiles contados desde la finalización del plazo para presentar el descargo por el funcionario denunciado.</p>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-six-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-six-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    <b>6. ¿Cuáles son las etapas de la gestión de la denuncia? EP, ahí se decide, se va a dar oportunidad para descargo, recolección de información y analizar eso para tomar decisión.</b>
                                </div>
                            </button>
                        </h2>
                        <div id="question-six-collapse" class="accordion-collapse collapse"
                            aria-labelledby="question-six-heading" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">Recibida la denuncia, la DGIT realizará una evaluación preliminar que consistirá en la verificación del cumplimiento de los requisitos establecidos en la pregunta 4, análisis de la competencia institucional respecto al hecho y a la persona denunciada.</p>
                                <br>
                                <p class="m-0">Cuando existan procedimientos específicos cuya competencia recaiga en otras dependencias de la CGR y que estén relacionados con el objeto de la denuncia, estas serán derivadas a la Unidad Organizacional correspondiente, previo análisis.</p>
                                <br>
                                <p class="m-0">En el plazo máximo de 5 días hábiles, se emitirá un Informe de Evaluación Preliminar que contendrá el análisis de hecho denunciado y la decisión de dar trámite o no la denuncia registrada, en caso de que la decisión sea negativa se archivará la denuncia.</p>
                                <br>
                                <p class="m-0">En los casos en que se le haya otorgado trámite a la denuncia, se notificará al denunciado para que dentro del plazo 30 días hábiles presente su descargo. A solicitud del denunciado, debidamente fundada, este plazo se podrá prorrogar una sola vez, por otros 30 días hábiles.</p>
                                <br>
                                <p class="m-0">Iniciado el trámite de la denuncia ciudadana se realizará actividades de recolección de información para el esclarecimiento de los hechos.</p>
                                <br>
                                <p class="m-0">Si durante el trámite de recolección de información se tuviere conocimiento de que el hecho denunciado se enmarca dentro de una actividad de control en curso por parte de la CGR, se halle en sede fiscal o con un procedimiento sumarial administrativo que impida la prosecución de la gestión de la denuncia, se emitirá un Informe de Cierre de Trámite fundado, con el cual finaliza el trámite y se procederá al archivo de la denuncia ciudadana.</p>
                                <br>
                                <p class="m-0">Finalizadas las actividades de recolección de información, se elaborará un Dictamen Conclusivo de Denuncia Ciudadana, el que contendrá un análisis de los hechos denunciados, el examen de las informaciones colectadas y la existencia o no de indicios suficientes que supongan la comisión de hechos de corrupción con relevancia penal y administrativa cometidos por funcionarios públicos en el ejercicio de sus funciones, y según sea el caso, la recomendación de:</p>
                                <br>
                                <p class="m-0">1. Archivo de la denuncia, por carecer de méritos suficientes conforme a lo establecido en la Ley Nº 7389/24.</p>
                                <p class="m-0">2. Remisión de antecedentes al Ministerio Público, cuando existan indicios suficientes de la comisión de hechos punibles.</p>
                                <p class="m-0">3. Remisión de antecedentes al ente administrativo, a fin de que inicie los trámites correspondientes o instruya un sumario administrativo, cuando existan indicios de irregularidades administrativas.</p>
                                <p class="m-0">4. Sugerencia de la realización de actividades de control, cuando aun existiendo méritos respecto a los hechos denunciados, no fue posible hallar información suficiente para la comunicación al Ministerio Público o la remisión al ente administrativo, por lo que se requiere la profundización de los hechos desde una perspectiva técnica.</p>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-seven-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-seven-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    <b>7. ¿Puedo darle seguimiento a mi denuncia? ¿Cómo?</b>
                                </div>
                            </button>
                        </h2>
                        <div id="question-seven-collapse" class="accordion-collapse collapse"
                            aria-labelledby="question-seven-heading" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">Sí. Al registrar tu denuncia el Portal Nacional de Denuncias Ciudadanas te proveerá un código con el cual podrás hacer seguimiento a la denuncia formulada.</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'vPreguntasFrecuentes',
}
</script>

<style>
.frequent-questions {
	background-color: #356aa0;
}
.frequent-questions h2 {
	color: #fff;
	font-weight: bold;
}
.frequent-questions li {
	list-style: none;
}
.frequent-questions .accordion .accordion-item {
	border-radius: 10px;
	border: 1px solid #dddfe1;
	background: #fff 0% 0% no-repeat padding-box;
}
.frequent-questions .accordion .accordion-item .accordion-button:focus {
	box-shadow: none !important;
}
.frequent-questions .accordion .accordion-item .accordion-button:not(.collapsed) {
	color: #000;
	box-shadow: none !important;
	background-color: #fff !important;
}
.frequent-questions .accordion button {
	padding: 12px 25px;
	border-radius: 10px !important;
}
</style>