<template>
    <section class="pasos-denuncias">
        <div class="container">
            <div class="section-title">
                <h3>¿Cómo puedes Denunciar?</h3>
            </div>

            <div class="row">
                
                <div class="col-lg-1"></div>
                
                <div class="col-lg-2 col-md-6 mt-4 mt-md-0">
                    <div class="box featured" style="height: 400px;">
                        <h3>Paso 01</h3>
                        <img src="assets/img/icons/home/Icon-awesome-user-secret.svg" height="45px" alt="..." class="step-1">
                        <p>
                            <sub>Para realizar tu denuncia, debes ingresar a la página web de la Contraloría General de la República y hacer click en el botón de DENUNCIAS habilitado para el efecto.</sub>
                        </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-4 mt-md-0">
                    <div class="box featured" style="height: 400px;">
                        <h3>Paso 02</h3>
                        <img src="assets/img/icons/home/icon-user.svg" height="45px" alt="..." class="step-1">
                        <p>
                            <sub>Debes identificar al funcionario público implicado en supuestos hechos de corrupción o faltas administrativas cometidas por el mismo. Además, indicar la institución donde cumple funciones.</sub>
                        </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-4 mt-md-0">
                    <div class="box featured" style="height: 400px;">
                        <h3>Paso 03</h3>
                        <img src="assets/img/icons/home/Trazado553.svg" height="45px" alt="..." class="step-1">
                        <p>
                            <sub>Debes realizar un relato claro y detallado de los hechos considerados como irregulares, adjuntando toda la documentación pertinente con la que cuentes.</sub>
                        </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-4 mt-md-0">
                    <div class="box featured" style="height: 400px;">
                        <h3>Paso 04</h3>
                        <img src="assets/img/icons/home/user-search.svg" height="45px" alt="..." class="step-1">
                        <p>
                            <sub>Debes recordar el número de Código designado, a fin de que puedas realizar el seguimiento a tu denuncia. En el apartado “CONTACTOS”, se encuentran disponibles los medios habilitados para realizar cualquier consulta al respecto.</sub>
                        </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-4 mt-md-0">
                    <div class="box featured" style="height: 400px;">
                        <h3>Paso 05</h3>
                        <img src="assets/img/icons/home/icon-message.svg" height="45px" alt="..." class="step-1">
                        <p>
                            <sub>La Dirección de Gestión de Denuncias Ciudadanas dependiente de la Dirección General de Integridad y Transparencia se encargará de comunicarte los resultados de tu denuncia.</sub>
                        </p>
                    </div>
                </div>

                <div class="col-lg-1"></div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: 'vDenunciar'
}
</script>