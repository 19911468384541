<template>
    <section class="servicio section-bg">
        <div class="container">
            <div class="section-title">
                <h3 style="color: white;">Denuncias Ciudadanas</h3>
                <br>
                <button class="btn-denuncia" @click="$router.push('/denuncia')"><b>Hace tu denuncia</b></button>
                &nbsp;
                <button class="btn-denuncia" @click="$router.push('/consulta')"><b>Consulta tu denuncia</b></button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'vDenunciasCiudadanas'
}
</script>

<style>
    .btn-denuncia {
        border: none;
        color: #fff;
        padding: 14px 102px;
        border-radius: 10px;
        background-color: #eb1515;
    }
</style>