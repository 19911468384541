<template>
    <section id="qs" class="about-us row w-100 justify-content-center m-0 pt-xl-5 pt-3 pb-5">
        <div class="text-center text-responsive-about-us p-lg-5 p-4">
            <h2>DIRECCIÓN GENERAL DE INTEGRIDAD PÚBLICA Y TRANSPARENCIA</h2>
            <h4>DIRECCIÓN DE GESTIÓN ANTICORRUPCIÓN</h4>
        </div>
        <div class="col-xl-5 col-lg-6 general-management p-0">
            <img src="@/assets/img/nosotros/card-direccion-general.png" alt="">
        </div>
        <div class="col-xl-5 about-us-description ms-xl-5 my-lg-5 p-0">
            <h2 class="text-center m-0 pt-4">¿Quiénes somos?</h2>
            <div class="p-4 text-about-us">
                <p>La Dirección General de Integridad y Transparencia es la encargada de fomentar la transparencia y la cultura de integridad a través de medidas preventivas, correctivas y demás mecanismos contra la corrupción, junto con la promoción de la participación ciudadana, para lo cual podrá coordinar y establecer alianzas estratégicas con el sector público y privado.</p>
                <p>Además, es la responsable de fomentar la ética pública al interior de la Contraloría General de la República, así como de la actualización continua del portal de transparencia institucional en la página web.</p>
                <p>Igualmente, tiene a su cargo el procedimiento sancionador aplicable a los funcionarios de la Contraloría General de la República, conforme a las normativas vigentes, dar trámite y respuestas a los pedidos de acceso a la información pública y a las denuncias ciudadanas.</p>
                <p>Para el cumplimiento de sus objetivos, tendrá la estructura siguiente:</p>
                <ol>
                <li>Dirección de Integridad Pública conformado por un Departamento de Transparencia Gubernamental.</li>
                <li>Dirección de Gestión de Denuncias ciudadanas conformado por un Departamento de Evaluación Inicial.</li>
                <li>Dirección de Coordinación y Participación Ciudadana conformada por un Departamento de Participación Ciudadana.</li>
                <li>Dirección de Análisis de Datos.</li>
                <li>Dirección de Asuntos Internos.</li>
                </ol>
                <p>Para el mejor desarrollo de sus funciones, la Dirección General contará con el soporte directo del:</p>
                <ul>
                    <ol>a. Departamento de Promoción de la Integridad.</ol>
                    <ol>b. Departamento de Instrucción Sumarial.</ol>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'vQuienesSomos',
}
</script>

<style>
.about-us .text-responsive-about-us {
	display: none;
	background-color: #fff;
}
.about-us .general-management {
	margin-right: -6.5%;
}
.about-us .about-us-description {
	color: #fff;
	border-radius: 8px;
	margin: 2rem 0rem 2rem 3rem;
	background-color: #356aa0;
	box-shadow: 0px 2px 48px #000 14;
}
.about-us .about-us-description .text-about-us {
	font-size: 17.5px;
}
</style>