<template>
    <section class="formulario-denuncia">
        <div class="container">
            <div class="section-title">
                <h3 class="color-text">Consulta de la denuncia</h3>
            </div>

            <div class="page-body">
                <div class="container-xl">
                    <div class="row g-4">
                        <div class="col-md-3">
                            <Form @submit="consultar" class="sticky-top">
                                <h5 class="color-text">
                                    Ingresa el nro de denuncia
                                </h5>
                                <div class="mb-4">
                                    <input class="form-control form-control-sm" type="text" name="consulta" v-model="consulta" />
                                </div>
                                <div class="mb-4">
                                    <button type="submit" class="btn btn-primary w-100" v-if="vacio">
                                        Consultar
                                    </button>
                                </div>
                            </Form>
                        </div>
                        <div class="col-md-9">
                            <div class="card">
                                
                                
                                <div class="card-body">
                                    <h3 class="card-title color-text">Situación de la denuncia</h3>
                                    <div class="stepper d-flex flex-column mt-5 ml-2">
                                        <div class="d-flex mb-1" v-for="(datos, index) in datosDenuncia" :key="datos.id">
                                            <div class="d-flex flex-column pr-4 align-items-center">
                                                <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">{{ index + 1 }}</div>
                                                <div class="line h-100"></div>
                                            </div>
                                            <div>
                                                <h5 class="text-dark">{{ datos.descripcion }}</h5>
                                                <p class="lead text-muted pb-3">
                                                    {{ datos.observacion }}<br>
                                                    {{ datos.fecha }}
                                                </p>
                                                <!-- <p class="lead text-muted pb-3">{{ datos.descripcion }}</p>
                                                <p class="lead text-muted pb-3">{{ datos.fecha }}</p> -->
                                            </div>
                                        </div>
                                            <!-- <div class="d-flex mb-1">
                                            <div class="d-flex flex-column pr-4 align-items-center">
                                                <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">2</div>
                                                <div class="line h-100"></div>
                                            </div>
                                            <div>
                                                <h5 class="text-dark">Clone application respository</h5>
                                                <p class="lead text-muted pb-3">Go to your dashboard and clone Git respository from the url in the dashboard of your application</p>
                                            </div>
                                            </div> -->
                                            <!-- <div class="d-flex mb-1">
                                            <div class="d-flex flex-column pr-4 align-items-center">
                                                <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1">3</div>
                                                <div class="line h-100 d-none"></div>
                                            </div>
                                            <div>
                                                <h5 class="text-dark">Make changes and push!</h5>
                                                <p class="lead text-muted pb-3">Now make changes to your application source code, test it then commit &amp; push</p>
                                            </div>
                                        </div> -->
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading" class="loader">
                <h5>Enviando Formulario..<br />Por favor espere..</h5>
            </div>
        </div>
    </section>
</template>

<script>
import DenunciaService from "@/services/denuncia.service";
// import axios from "axios";
// import Swal from "sweetalert2";
// import JSONService from "@/services/JSON.service";
// import Select2 from "vue3-select2-component";
// import html2pdf from "html2pdf.js";
// import xml2js from 'xml2js';
// import api from '@/services/api.js';
// import { Form, Field, ErrorMessage } from "vee-validate";
import { Form } from "vee-validate";

export default {
    name: "vFormConsultas",
    components: { Form },
    // components: { Select2, Form, Field, ErrorMessage },
    data() {
        return {
            consulta: '',
            vacio: true,
            datosDenuncia: [],
            // datosDenuncia: {
            //     id: '',
            //     nroActividad: '',
            //     observacion: '',
            //     descripcion: '',
            //     fecha: '',
            //     orden: ''
            // }
        };
    },

    metaInfo() {
        return {
            title: "Hace tu denuncia",
        };
    },

    methods: {

        consultar() {
            let params = {};
            params["codigo"] = this.consulta;

            DenunciaService.getDenuncia(params).then(
                (response) => {
                    this.datosDenuncia = response.data;
                    // console.log(response.data);
                },
                (error) => {
                    // console.log('Error: ', error.data);
                    if (error.response && error.response.status === 403) {
                        console.log('Ha ocurrido un error');
                    }else if (error.response && error.response.status === 401) {
                        // Realizar acción de cierre de sesión aquí
                        console.log('Ha ocurrido un error');
                    }
                }
            );
            
            
            this.consulta = '';
        },

        refresh() {
            // this.arrayDenunciado = [];
            // this.arrayDenunciante = [];

            // this.$router.replace("/estado-denuncia");
        },

        // onFileChange(event) {
            // if (this.cantArchivo > 3) {
            //     Swal.fire({
            //         title: "Error al subir el archivo",
            //         text: `No se puede subir más de 4 archivos por denuncia.`,
            //         icon: "error",
            //         confirmButtonColor: "#356AA0",
            //     });
            //     return;
            // }
            // const file = event.target.files[0];
            // if (file && file.size > this.maximoPermitido) {
            //     Swal.fire({
            //         title: "Error al subir el archivo",
            //         text: `El archivo seleccionado es demasiado grande. El tamaño máximo permitido es de ${ this.maximoPermitido / 5000000 } MB.`,
            //         icon: "error",
            //         confirmButtonColor: "#356AA0",
            //     });
            //     event.target.value = null; // Limpia el valor del componente de entrada de archivo
            //     this.selectArchivo = null; // Limpia el archivo seleccionado en el modelo de datos
            // } else {
            //     this.cantArchivo = this.cantArchivo + 1;
            //     this.selectArchivo = file;
            // }
            // this.nuevoArchivo();
        // },

        
        //crear archivo y enviar peticionSOAP
        // async convertirYEnviar() {
        //     this.loading = true;
        //     try {
        //         const base64data = await this.download();
        //         await this.enviarSOAP(base64data);
        //     } catch (error) {
        //         // console.error("Error en la operación completa:", error);
        //         this.loading = false;
        //     }
        // },
        
        // decargar pdf base 64
        // async download() {
        //     try {
        //         // console.log("descargando!!");
        //         const el = document.getElementById("vue3SimpleHtml2pdf"); //ojo con la ref y el byId
        //         if (!el) {
        //             return;
        //         }
        //         //habilitar bajar el archivo
        //         // html2pdf().from(el).set(this.pdfOptions).save(this.exportFilename);
        //         // this.iFrame = await html2pdf().from(el).output('bloburl');

        //         const blobArchive = await html2pdf().from(el).output("blob");
        //         const reader = new FileReader();
        //         return new Promise((resolve, reject) => {
        //             reader.onload = function () {
        //                 const base64data = reader.result.split(",")[1];
        //                 // console.log("PDF en base64:", base64data);
        //                 resolve(base64data);
        //             };
        //             reader.onerror = function (error) {
        //                 reject(error);
        //             };
        //             reader.readAsDataURL(blobArchive);
        //             // console.log(this.blobArchive);
        //         });
        //     } catch (error) {
        //         console.error("Error durante la conversión a base64:", error);
        //         throw error;
        //     }
        // },

        

        // cerrarModal() {
        //     // const myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'));
        //     // myModal.hide();
        // },

        // isRequired(value) {
        //     if (!value) {
        //         // console.log("Este campo es requerido");
        //         return "Este campo es requerido";
        //     }
        //     return true;
        // },

        // validateEmail(value) {
        //     if (!value) {
        //         return "Este campo es requerido";
        //     }

        //     const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        //     if (!regex.test(value)) {
        //         return "Este campo debe ser un correo";
        //     }
        //     return true;
        // },

        // async crear() {
        

        // uploadFile(archivo, uid) {
        //     return new Promise((resolve, reject) => {
        //         var reader = new FileReader();
        //         const binary = reader.readAsDataURL(archivo);
        //         console.log(binary.toString());

        //         const formData = new FormData();
        //         formData.append("file", archivo, archivo.name);
        //         let headers = {
        //             "Content-Type": "multipart/form-data",
        //             accept: "*/*",
        //         };

        //         const fileData = { uid: uid, formData: formData, headers: headers };
        //         resolve(this.subirArchivo(fileData));
        //         console.log(reject);
        //     });
        // },

        // actualizarMun() {
        //     this.denuncia.ciudad = "";
        // },

        // seleccionOtraOEE({ id, text }) {
        //     console.log("metodo seleccionOtraOEE --->" + id + text);
        // },

        // guardarCodigo() {
        //     window.print();
        // },

        // copiarCodigo() {
        //     this.copiado = false;
        //     navigator.clipboard.writeText(
        //         (this.copiar_comprobante = this.denuncia_response.comprobante)
        //     );
        //     this.copiado = true;
        // },

        // funcionarioBoolean(event) {
        //     if (event.target.value === "si") {
        //         this.denunciados.esFuncionario = true;
        //     } else if (event.target.value === "no") {
        //         this.denunciados.esFuncionario = false;
        //     }
        // },

        // imprimir() {},

        // botonDenuncia() {
        //     if (
        //         this.denunciantes.nombreCompleto == "" &&
        //         this.denunciantes.tipoDocumento == "" &&
        //         this.denunciantes.nroDocumento == "" &&
        //         this.denunciantes.email == "" &&
        //         this.denunciantes.telefono == "" &&
        //         this.denunciados.nombreCompleto == "" &&
        //         this.denunciados.tipoDocumento == "" &&
        //         this.denunciados.nroDocumento == "" &&
        //         this.funcionario == "" &&
        //         this.denuncia.otraInstitucion == "" &&
        //         this.denuncia.fechaDeLosHechos == "" &&
        //         this.denuncia.institucionDenunciada == "" &&
        //         this.denuncia.ciudad == "" &&
        //         this.denuncia.departamento == "" &&
        //         this.denuncia.descripcion == "" &&
        //         this.denuncia.urlDocumentoAdjunto == "" &&
        //         this.denuncia.pretension == ""
        //     ) {
        //         this.vacio = false;
        //     } else {
        //         this.vacio = true;
        //     }
        // },

        // limpiarDenunciantes() {
        //     (this.denunciantes.nombreCompleto = ""),
        //     (this.denunciantes.tipoDocumento = ""),
        //     (this.denunciantes.nroDocumento = ""),
        //     (this.denunciantes.email = ""),
        //     (this.denunciantes.telefono = "");
        // },

        // limpiarDenunciados() {
        //     (this.denunciados.nombreCompleto = ""),
        //     (this.denunciados.tipoDocumento = ""),
        //     (this.denunciados.nroDocumento = ""),
        //     (this.funcionario = "");
        // },

        // limpiarDenuncia() {
        //     this.denuncia.uid = "";
        //     this.denuncia.comprobante = "";
        //     this.denuncia.tipoDenuncia = "rendiciones";
        //     this.denuncia.denunciaAnonima = true;
        //     this.denuncia.denunciaOtraInstitucion =  false;
        //     this.denuncia.otraInstitucion = "";
        //     this.denuncia.fechaDeLosHechos = "";
        //     this.denuncia.institucionDenunciada = "";
        //     this.denuncia.otraInstitucionDenunciada = null;
        //     this.denuncia.ciudad = "";
        //     this.denuncia.departamento = "";
        //     this.denuncia.descripcion = "";
        //     this.denuncia.urlDocumentoAdjunto = "";
        //     this.denuncia.pretension = "";
        //     this.denuncia.estado = null;
        //     this.denuncia.administradoPorUsuario = null;
        //     this.denuncia.fechaCreacion = new Date();
        //     this.denuncia.denunciados = [];
        //     this.denuncia.denunciantes = [];
        //     this.denuncia.archivos = [];
        //     this.denuncia.base64data = "";
        //     this.arrayDenunciado = [];
        //     this.arrayDenunciante = [];
        //     this.arrayFile = [];
        // },

        // ...mapMutations('apiArchivos', ['nuevoArchivo']),

        // ...mapActions('apiDenuncias', ['crearDenuncia', 'obtenerInstituciones']),

        // ...mapActions('apiArchivos', ['subirArchivo']),

        // activeType(active) {
        //   //console.log("elegí: " + active);
        //     if (active == "rendiciones") {
        //         (this.denuncia.tipoDenuncia = "Rendiciones de cuentas en concepto de FONACIDE y Royalties"),
        //         (this.active_rendiciones = true);
        //         (this.active_presentacion = false),
        //         (this.active_licitaciones = false),
        //         (this.active_manejo = false),
        //         (this.active_funcionarios = false),
        //         (this.active_otros = false);
        //     } else if (active == "presentacion") {
        //         (this.denuncia.tipoDenuncia = "Presentación o no de las Declaraciones Juradas"),
        //         (this.active_presentacion = true);
        //         (this.active_rendiciones = false),
        //         (this.active_licitaciones = false),
        //         (this.active_manejo = false),
        //         (this.active_funcionarios = false),
        //         (this.active_otros = false);
        //     } else if (active == "licitaciones") {
        //         (this.denuncia.tipoDenuncia = "Licitaciones Públicas"),
        //         (this.active_licitaciones = true);
        //         (this.active_rendiciones = false),
        //         (this.active_presentacion = false),
        //         (this.active_manejo = false),
        //         (this.active_funcionarios = false),
        //         (this.active_otros = false);
        //     } else if (active == "manejo") {
        //         (this.denuncia.tipoDenuncia = "Manejo indebido del Bien Público"),
        //         (this.active_manejo = true);
        //         (this.active_rendiciones = false),
        //         (this.active_presentacion = false),
        //         (this.active_licitaciones = false),
        //         (this.active_funcionarios = false),
        //         (this.active_otros = false);
        //     } else if (active == "funcionarios") {
        //         (this.denuncia.tipoDenuncia = "Contra Funcionarios de la CGR"),
        //         (this.active_manejo = false);
        //         (this.active_rendiciones = false),
        //         (this.active_presentacion = false),
        //         (this.active_licitaciones = false),
        //         (this.active_funcionarios = true),
        //         (this.active_otros = false);
        //     } else if (active == "otros") {
        //         (this.denuncia.tipoDenuncia = "Otros"), (this.active_otros = true);
        //         (this.active_rendiciones = false),
        //         (this.active_presentacion = false),
        //         (this.active_licitaciones = false),
        //         (this.active_funcionarios = false),
        //         (this.active_manejo = false);
        //     }
        // },

        // tooggleShowForm(anonymous) {
        //     // console.log("metodo: tooggleShowForm " + " variable: " + anonymous);
        //     if (true == anonymous) {
        //         this.showForm = false;
        //         this.denuncia.denunciaAnonima = true;
        //     }
        //     if (false == anonymous) {
        //         this.showForm = true;
        //         this.denuncia.denunciaAnonima = false;
        //     }
        // },

        // activeChange(act) {
        //     if (true == act) {
        //         this.denunciantes.protegerDatos = true;
        //     }
        //     if (false == act) {
        //         this.denunciantes.protegerDatos = false;
        //     }
        // },

        // activeChange_other(act) {
        //     // console.log("metodo: activeChange_other " + " variable: " + act);
        //     if (true == act) {
        //         this.denuncia.denunciaOtraInstitucion = true;
        //     }
        //     if (false == act) {
        //         this.denuncia.denunciaOtraInstitucion = false;
        //     }
        // },

        // checkLength() {
        //     if (this.denuncia.descripcion.length > this.maxLength) {
        //         this.denuncia.descripcion = this.denuncia.descripcion.slice(0, this.maxLength);
        //     }
        // },
        // checkLengthTwo() {
        //     if (this.denuncia.pretension.length > this.maxLength) {
        //         this.denuncia.pretension = this.denuncia.pretension.slice(0, this.maxLength);
        //     }
        // },

        // nuevoDenunciado() {
        //     // console.log("metodo:nuevoDenunciado!! ");
        //     if (this.carga_denunciados) {
        //         // console.log("elimina");
        //         this.arrayDenunciado.shift();
        //     }

        //     if (
        //         !(
        //             this.denunciados.nombreCompleto == "" &&
        //             this.denunciados.tipoDocumento == "" &&
        //             this.denunciados.nroDocumento == ""
        //         )
        //     ) {
        //         if (!(this.denunciados.nombreCompleto.trim() == "")) {
        //             this.arrayDenunciado.push({
        //                 nombreCompleto: this.denunciados.nombreCompleto,
        //                 tipoDocumento: this.denunciados.tipoDocumento,
        //                 nroDocumento: this.denunciados.nroDocumento,
        //                 esFuncionario: this.denunciados.esFuncionario,
        //                 esEliminado: this.denunciados.esEliminado,
        //                 denunciaUid: this.denunciados.denunciaUid,
        //             });
        //         }

        //         this.carga_denunciados = false;
        //     }
        // },
        // nuevoDenunciante() {
        //     // console.log("metodo:nuevoDenunciante ");
        //     if (this.carga_denunciantes) {
        //         this.arrayDenunciante.shift();
        //     }

        //     if (
        //         !(
        //             this.denunciantes.nombreCompleto == "" ||
        //             this.denunciantes.tipoDocumento == "" ||
        //             this.denunciantes.nroDocumento == "" ||
        //             this.denunciantes.email == "" ||
        //             this.denunciantes.telefono == ""
        //         )
        //     ) {
        //         this.arrayDenunciante.push({
        //             nombreCompleto: this.denunciantes.nombreCompleto,
        //             tipoDocumento: this.denunciantes.tipoDocumento,
        //             nroDocumento: this.denunciantes.nroDocumento,
        //             email: this.denunciantes.email,
        //             telefono: this.denunciantes.telefono,
        //             protegerDatos: this.denunciantes.protegerDatos,
        //             esEliminado: this.denunciantes.esEliminado,
        //             denunciaUid: this.denunciantes.denunciaUid,
        //         });
        //         this.carga_denunciantes = false;
        //         this.limpiarDenunciantes();
        //     } else {
        //         this.$swal({
        //             title: "Mensaje",
        //             html: "Todos los datos personales son <strong>requeridos</strong> !!",
        //             icon: "warning",
        //             confirmButtonColor: "#356AA0",
        //         });
        //     }
        // },

        // nuevoArchivo() {
        //     if (this.selectArchivo) {

        //         this.arrayFile.push({
        //             selectArchivo: this.selectArchivo,
        //             base64: this.base64,
        //         });
        //     }
        // },

        
        
        
    },
    mounted() {

    },

    computed: {
        // ...mapState('apiDenuncias', ['entradas', 'entrada']),
        // ...mapState('apiArchivos', ['archivosArray']),

        // remainingChars() {
        //     return this.maxLength - this.denuncia.descripcion.length;
        // },

        // remainingCharsTwo() {
        //     return this.maxLength - this.denuncia.pretension.length;
        // },

        // municipiosFiltrados() {
        //     // console.log(
        //         // "COMPUTED municipiosFiltrados: " + this.denuncia.departamento
        //     // );
        //     if (this.denuncia.departamento) {
        //         return this.mun[this.denuncia.departamento];
        //     } else {
        //         return [];
        //     }
        // },

        // nombreInstituciones() {
        //     return this.instituciones.map(
        //         //@ts-ignore
        //         (institucion) => institucion.nombre
        //     );
        // },

        // otrosSelected() {
        //     return this.denuncia.institucionDenunciada === "Otros";
        // },
    },

    // watch: {
    //     "denuncia.institucionDenunciada"(newV, preV) {
    //         if (newV !== "OTRA INSTITUCIÓN") {
    //             // console.log("se eligio uns institución!");
    //             this.denuncia.otraInstitucionDenunciada = null;
    //             this.isOtraInstitucion = false;
    //             console.log(preV);
    //         } else {
    //             this.isOtraInstitucion = true;
    //         }
    //     },
    // },

    created() {
        // this.arrays()
        // this.obtenerInstituciones().then(res => {
        //   this.instituciones = res
        // })//.catch(error => console.log())
        // this.$store.dispatch('goToTop')
    },
};
</script>

<style>
/* .pasos-form-denuncias .active {
    padding: 20px;
    background: #356aa0;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    color: #fff;
}

.pasos-form-denuncias .inactive {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
}

.active-btn-denuncia-yes {
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 13px 70px;
    background-color: #356aa0;
    border-radius: 8px 0px 0px 8px;
    box-shadow: 0px 3px 6px #00000029;
}



 */



.stepper .line {
    width: 2px;
    background-color: lightgrey !important;
}
.stepper .lead {
    font-size: 1.1rem;
}


</style>