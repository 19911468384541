<template>
    <section id="ct" class="how-work row m-auto justify-content-center pb-xl-5 py-3">
        <div class="text-center img-responsive-how-work p-0">
            <img src="@/assets/img/nosotros/img-como-trabajamos.webp" alt="">
        </div>
        <div class="col-xl-5 how-work-description p-0 my-5">
            <h2 class="text-center m-0 pt-4">¿Cómo trabajamos?</h2>
            <div class="p-4 pb-0 text-how-work">
                <p>En la <b>Dirección General</b>, trabajamos con el firme compromiso de garantizar la integridad y la transparencia en la gestión pública, promoviendo el cumplimiento de la legislación vigente y fomentando la participación activa de la ciudadanía en el control de los asuntos públicos.</p>
                <p>Nuestro enfoque se basa en la <b>prevención y detección de irregularidades</b>, fortaleciendo los mecanismos de rendición de cuentas y asegurando que los recursos del Estado sean utilizados de manera eficiente, ética y conforme a las normas establecidas.</p>
            </div>
        </div>
        <div class="col-xl-6 general-management p-0">
            <img src="@/assets/img/nosotros/img-como-trabajamos.webp" alt="">
        </div>
    </section>
</template>

<script>
export default {
    name: 'vComoTrabajamos',
}
</script>

<style>
.how-work {
	width: 90%;
}
.how-work .img-responsive-how-work {
	display: none;
}
.how-work .how-work-description {
	z-index: 1;
    color: #fff;
	margin-right: -3.5%;
	border-radius: 8px;
	background-color: #356aa0;
	box-shadow: 0px 2px 48px #000 14;
}
.how-work .how-work-description .text-how-work {
	font-size: 17.5px;
}
.how-work .general-management img {
	width: 100%;
}
</style>