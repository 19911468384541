import api from './api';
class DenunciaService {
    getDenuncia(params) {
        console.log(params);
        return api.get("/portal-denuncias/seguimiento", { params });
    }

    saveDenuncia(params) {
        return api.post("/portal-denuncias/insert", params);
    }

    uploadFileDenuncia(file, nombreTabla, onUploadProgress) {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('nombreTabla', nombreTabla);
        return api.post('/portal-denuncias/cargar-archivo/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress
        });
    }

    // uploadFileDenuncia(file, nombreTabla, documentoParametricoGralId, onUploadProgress) {
    //     let formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('nombreTabla', nombreTabla);
    //     if(documentoParametricoGralId){
    //         formData.append('documentoParametricoGralId',documentoParametricoGralId );
    //         console.log(documentoParametricoGralId, 'append?')
    //     }
    //     return api.post('/sftp/cargar-archivo/', formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         },
    //         onUploadProgress
    //     });
    // }
}
export default new DenunciaService();