import { createRouter, createWebHistory } from 'vue-router';
import vHome from './views/vHome.vue';
import vDenuncias from './views/vDenuncias.vue';
import vNosotros from './views/vNosotros.vue';
import vConsulta from './views/vConsulta.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: vHome
    },
    {
        path: '/denuncia',
        name: 'Denuncia',
        component: vDenuncias
    },
    {
        path: '/nosotros',
        name: 'Nosotros',
        component: vNosotros
    },
    {
        path: '/consulta',
        name: 'Consulta',
        component: vConsulta
    },
    // {
    //     path: '/estado-denuncia',
    //     name: 'vEstadoDenuncia',
    //     component: () => import('@/views/Page/vEstadoDenuncia.vue'),
    //     meta: { requiresAuth: false, userRoles: [] },
    // },
    // {
    //     path: '/estado-denuncia/detail/:id',
    //     name: 'vEstadoDenunciaDetail',
    //     component: () => import('@/views/Page/vEstadoDenunciaDetail.vue'),
    //     meta: { requiresAuth: false, userRoles: [] },
    // },
    // {
    //     path: '/boletin/:id',
    //     name: 'vBoletinInteres',
    //     component: () => import('@/views/Page/vBoletinInteres.vue'),
    //     meta: { requiresAuth: false, userRoles: [] },
    // },
    // {
    //     path: '/todos-los-boletines',
    //     name: 'vAllBoletin',
    //     component: () => import('@/views/Page/vAllBoletin.vue'),
    //     meta: { requiresAuth: false, userRoles: [] },
    // },
    // {
    //     path: '/nosotros',
    //     name: 'vNosotros',
    //     component: () => import('@/views/Page/vNosotros.vue'),
    //     meta: { requiresAuth: false, userRoles: [] },
    //     children: [
    //         {
    //             path: '/#ct',
    //             component: () => import('@/views/Page/vNosotros.vue'),
    //             meta: { requiresAuth: false, userRoles: [] },
    //         },
    //         {
    //             path: '#pf',
    //             component: () => import('@/views/Page/vNosotros.vue'),
    //             meta: { requiresAuth: false, userRoles: [] },
    //         }
    //     ]
    // },
    // {
    //     path: '/ver-solicitudes',
    //     name: 'vVerSolicitudes',
    //     component: () => import('@/views/Page/vVerSolicitudes.vue'),
    //     meta: { requiresAuth: false, userRoles: [] },
    // },
    // {
    //     path: '/estado-solicitud',
    //     name: 'vEstadoSolicitud',
    //     component: () => import('@/views/Page/vEstadoSolicitud.vue'),
    //     meta: { requiresAuth: false, userRoles: [] },
    // },
    // {
    //     path: '/estado-solicitud/detail/:id',
    //     name: 'detail ',
    //     component: () => import('@/views/Page/vEstadoSolicitudDetail.vue'),
    //     meta: { requiresAuth: false, userRoles: [] },
    // },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
