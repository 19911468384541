import axios from 'axios';
const instance = axios.create({
    // baseURL: 'http://10.168.100.81:8080/Apia/services', // Este usar para capac-virtu
    // baseURL: 'https://10.168.100.128/Apia/services', // Este usar para produccion
    // baseURL: 'https://201.217.50.238:35501/Apia/services', // Este usar para produccion
    // headers: {
    //     "Content-Type": "text/xml",
    //     SOAPAction: "ws_generacion_expediente_extendido",
    // },
    // baseURL: 'http://10.168.252.29:8080/sipo-backend/',
    // baseURL: 'https://denuncias.contraloria.gov.py/sipo-backend/', //prod
    baseURL: 'https://denuncias.contraloria.gov.py/sipo-backend/', //Produccion SIPO
    // baseURL: 'http://localhost:8080/sipo-backend/',
    headers: {
        "Content-Type": "application/json",
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
    },
});
export default instance;