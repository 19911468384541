<template>
    <section class="contacto">
        <div class="container">

            <div class="section-title">
                <h3>Contáctanos</h3>
                <p>Si deseas dejarnos tu consulta o sugerencia, encuéntranos en:</p>
            </div>

            <div class="row">
                <div class="col-lg-3">
                    <div class="info-box mb-4">
                        <i class="bx bx-map"></i>
                        <h3>Dirección</h3>
                        <p>Bruselas N° 1880</p>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="info-box mb-4">
                        <i class="bx bx-envelope"></i>
                        <h3>E-mail</h3>
                        <p>denuncias@contraloria.gov.py</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="info-box  mb-4">
                        <i class="bx bx-phone-call"></i>
                        <h3>Teléfono</h3>
                        <p>+595 21 6200 436</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="info-box  mb-4">
                        <i class="bx bx-time"></i>
                        <h3>Horario de atención</h3>
                        <p>Lunes a viernes de 07:00 a 13:00 hs</p>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-lg-12">
                    <iframe class="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1041.9766016367287!2d-57.56852005095372!3d-25.28823858497997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945da8a141693f41%3A0x39e6f0b0a1eb8862!2sContralor%C3%ADa%20General%20de%20la%20Rep%C3%BAblica!5e0!3m2!1ses-419!2spy!4v1700154635721!5m2!1ses-419!2spy" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
                </div>

                <!-- <div class="col-lg-6">
                    <form @submit.prevent="enviarFormulario" class="php-email-form">
                        <div class="row">
                            <div class="col form-group">
                            <input type="text" v-model="formulario.nombre" name="name" class="form-control" id="name" placeholder="Ingrese su nombre" required>
                            </div>
                            <div class="col form-group">
                            <input type="email" v-model="formulario.email" class="form-control" name="email" id="email" placeholder="Ingrese su email" required>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" v-model="formulario.asunto" class="form-control" name="subject" id="subject" placeholder="Ingrese su asunto" required>
                        </div>
                        <div class="form-group">
                            <textarea v-model="formulario.mensaje" class="form-control" name="message" rows="5" placeholder="Ingrese su mensaje" required></textarea>
                        </div>
                        <div class="my-3">
                            <div class="loading">Cargando</div>
                            <div class="error-message"></div>
                            <div class="sent-message" v-if="enviado">Su mensaje ha sido enviado. Gracias!</div>
                        </div>
                        <div class="text-center"><button type="submit" class="btn-contacto">Enviar</button></div>
                    </form>
                </div> -->

            </div>

        </div>
    </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    name: 'vContacto',
    data() {
        return {
            formulario: {
                nombre: '',
                email: '',
                asunto: '',
                mensaje: ''
            },
            enviado: false
        }
    },
    methods: {
        enviarFormulario() {
            // axios.post('https://denuncias.contraloria.gov.py/api-correo/index.php/emailcontroller/enviarCorreo', this.formulario)
            axios.post('http://localhost/api-correo/index.php/emailcontroller/enviarCorreo', this.formulario)
            .then(response => {
                console.log('Esta es la respuesta success del servidor:', response.data.success);
                if (response.data.success) {
                    // Éxito
                    console.log('Correo enviado exitosamente');
                    
                    this.formulario.nombre = '';
                    this.formulario.email = '';
                    this.formulario.asunto = '';
                    this.formulario.mensaje = '';
            
                    Swal.fire({
                        title: "Se ha enviado exitosamente!",
                        icon: "success",
                        confirmButtonColor: "#356AA0",
                    });
                } else {
                    // Error
                    console.error('Error al enviar el correo:', response.data.error);
                    Swal.fire({
                        title: "Ha ocurrido un error al enviar el formulario",
                        text: `Por favor inténtelo mas tarde.`,
                        icon: "error",
                        confirmButtonColor: "#356AA0",
                    });
                }
            })
            .catch(error => {
                console.error('Error en la solicitud HTTP:', error);
            });
        }
    }
}
</script>